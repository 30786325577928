import axios from 'axios';
import { removeToken } from '../utils/auth'
import { Toast } from 'antd-mobile';
const http = axios.create();

http.interceptors.request.use(config => {
    config.headers.Authorization = localStorage.getItem('token') || "";
    return config;
});

http.interceptors.response.use(response => { 
    return response;
},
error => {
    
    const { response: { status, statusText }} = error
    const { response } = error
    const info = response.data
    
    if (status === 401 || info.status === 401) {
        if (window.location.pathname === '/login') return;        
        Toast.info(statusText)
        setTimeout(() => {
            removeToken()
         window.location.href='/login'
        }, 3000);

    }
    return Promise.reject(error)
}
)

export default http;