import http from '../utils/axios'
// import axios from 'axios';
// 发送验证码
export function getverificationCode(data) {
    return http.get("/api/ttMission/smsCode", {params:data});
}
// 登录
export function login(data) {
    return http.get("/api/ttMission/login", {params:data});
}
// 登出
export function logout(data) {
    return http.get("/api/ttMission/logout", {params:data});
}
// 获取用户详情
export function getPromoterInfo(data) {
    return http.get("/api/ttMission/promoterInfo", {params:data});
}
// 游戏列表
export function gameList(data) {
    return http.get("/api/ttMission/gameList", {params:data});
}
// 游戏详情
export function gameDetail(data) {
    return http.get("/api/ttMission/gameDetail", {params:data});
}
// 即将上线游戏列表
export function getGameOnlineSoonList(data) {
  return http.get("/api/ttMission/getGameOnlineSoonList", { params: data });
}
// 即将上线的游戏详情
export function newGameDetail(data) {
  return http.get("/api/ttMission/newGameDetail", { params: data });
}
//公告数据
export function getGameNoticeList(data){
return http.get("/api/ttMission/getGameNoticeList",{params:data})
}
// 生成推广二维码
export function QRCode(data) {
    return http.get("/api/ttMission/QRCode", {params:data});
}
// 推广数据
export function promoDataList(data) {
    return http.get("/api/ttMission/promoDataList", {params:data});
}
// 推广过的游戏
export function promoGameData(data) {
    return http.get("/api/ttMission/promoedGames", {params:data});
}
// 游戏详情视频榜单
export function getGameVideoList(data) {
    return http.post("/developer/api/get_video_info", {"alias_ids": data})
}

// 当前用户主页相关数据
export function getPromofitData(data) {
    return http.get("/api/ttMission/getPromofitData", {params:data});
}

// 推广下级获取下级数据信息
export function promofitTeamData(data) {
    return http.get("/api/ttMission/promofitTeamData", {params:data});
}

// 推广下级提现信息
export function getPromotCashHistory(data) {
    return http.get("/api/ttMission/getPromotCashHistory", {params:data});
}

// 将微信授权后的code传递到后端
export function sendCode(data) {
    return http.get("/api/ttMission/sendCode", {params:data});
}

export function reward1yuan(data) {
    return http.get("/api/ttMission/reward", {params:data});
}

// 获取快手拍视频数据
export function getDataForKSPSP(data) {
    return http.get("/api/ttMission/kuaishouPromo", {params:data});
}

//更新用户可抽奖次数
export function addPromoterLuckDrawCount(data){
    return http.post("/api/ttMission/addPromoterLuckDrawCount",{...data})
}

//获取抽奖信息
export function getLuckDrawInfo(data) {
  return http.get("/api/ttMission/getluckDrawInfo", { params: data });
}
//抽奖api
export function doLuckDraw(data) {
    return http.post("/api/ttMission/ExecuteLuckyDraw", {
      ...data,
    });
}

// 当前用户出海相关数据
export function getOverseasProfitData(data) {
  return http.get("/api/ttMission/getOverseasProfitData", { params: data });
}
