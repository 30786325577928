import React from 'react';
import Header from '../../../../component/header'


class Bannerpage extends React.Component<any>{
  goBack = () => {
    this.props.history.goBack()
   }

   state={
    NoticeTitle:'',
    NoticeText:'',
   }

   componentDidMount(){
    const data=this.props.location.state.data;
    this.setState({
      NoticeTitle:data.NoticeTitle,
      NoticeText:data.NoticeText,
    })
   }

  render(){
    const {NoticeTitle,NoticeText}=this.state;
    return(
      <div>
        <Header text={NoticeTitle} arrow={true} goBack={ this.goBack}></Header>
        <div ><p style={{whiteSpace:'pre-wrap',padding:'1rem'}}>{NoticeText}</p></div>
      </div>

    )
  }
}

export default Bannerpage