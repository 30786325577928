import { ActivityIndicator, Modal, Toast } from "antd-mobile";
import React from "react";
import {
  addPromoterLuckDrawCount,
  doLuckDraw,
  getLuckDrawInfo,
} from "../../api";
import "./index.css";
import luckDrawBgUrl from "../../assets/luckDraw/luckbg1.jpg";
import luckContentUrl from "../../assets/luckDraw/luckContent.png";
import ruleBtnUrl from "../../assets/luckDraw/ruleBtn.png";
import doluckDrawUrl from "../../assets/luckDraw/doluckDraw.png";
import xinyun from "../../assets/luckDraw/xinyun.png";
import zhongJ from "../../assets/luckDraw/zJ.png";
import ruleUrl from "../../assets/luckDraw/rule.png";
import closeBtn from "../../assets/luckDraw/closeBtn.png";
import againDo from "../../assets/luckDraw/againDo.png";
import countUrl from "../../assets/luckDraw/count.png";
import amountUrl from "../../assets/luckDraw/amount.png";
import daJinag from "../../assets/luckDraw/daJinag.png";
import listLeftUrl from "../../assets/luckDraw/listLeft.png";
import listRightUrl from "../../assets/luckDraw/listRight.png";
import listBottomUrl from "../../assets/luckDraw/listBottom.png";
//抽奖列表
const LuckDrawList = (props: any) => {
  let luckDrawList = [];
  luckDrawList = props.luckDrawList;
  const text = (item: any) => {
    const awardAmount =
      1 * item.awardAmount === 0 ? "幸运奖" : item.awardAmount + "元";
    // const time = `${moment(item.createdAt).format("MM-DD HH:mm")}`;
    if (1 * item.awardAmount >= 15.88) {
      return (
        <span
          style={{ color: "#e1fef4" }}
        >{`喜从天降!【${item.phone}】抽中${awardAmount}大奖`}</span>
      );
    } else if (1 * item.awardAmount > 0 && 1 * item.awardAmount < 15.88) {
      return (
        <span
          style={{ color: "#e1fef4" }}
        >{`恭喜:【${item.phone}】获得 ${awardAmount} 奖金`}</span>
      );
    }
  };
  const index1 = luckDrawList && luckDrawList.length - 10;
  return (
    <div className="listContaint">
      <div>
        {luckDrawList &&
          luckDrawList.length !== 0 &&
          luckDrawList.map((item: any, index: any) => {
            if (index1 <= index) {
              return <div key={`${index}-${item.id}-${item.createdAt}`}>{text(item)}</div>;
            } else {
              return null;
            }
          })}
      </div>
    </div>
  );
};

const LuckInfo = (props: any) => {
  const { luckyDrawAmount, doluckDraw } = props;
  let amount: any;
  let item: any = "";
  amount = 1 * luckyDrawAmount;
  if (amount !== 0) {
    if (amount >= 15.88) {
      item = <img className="zhongJ" src={daJinag} alt="" />;
    } else {
      item = <img className="zhongJ" src={zhongJ} alt="" />;
    }
  } else {
    item = <img className="xinyun" src={xinyun} alt="" />;
  }

  return (
    <div className="luckInfo">
      {item}
      {amount !== 0 && <span>{amount + "元"}</span>}
      <img
        className="againDo"
        onClick={() => {
          doluckDraw();
        }}
        src={againDo}
        alt=""
      />
    </div>
  );
};

class luckDraw extends React.Component<any> {
  state = {
    loading: true, //页面加载标识
    lotteryIsOver: false, //抽奖标识
    RemainingTimes: 0, //剩余抽奖次数
    allLuckDrawAmount: 0, //累计奖金
    luckDrawList: [], //抽奖记录
    canluckDrawCount: 0, //可抽奖的总次数
    hasBennluckDrawNum: 0, //已抽奖次数
    luckyDrawAmount: 0, //抽奖金额
    down: true,
    refreshing: false,
    WinPop: false, //抽奖弹窗
    rulePop: false,
  };
  componentDidMount() {
    this.getLuckDrawInfo();
    setInterval(() => this.getLuckDrawInfo(), 10000);
  }

  componentWillUnmount() {
    this.setState = () => false;
  }

  //获取抽奖信息
  getLuckDrawInfo = async () => {
    const token = localStorage.getItem("token");
    try {
      const { data }: any = await getLuckDrawInfo({
        token,
      });

      if (data.code === "ok") {
        const {
          canluckDrawCount,
          hasBennluckDrawNum,
          allLuckDrawAmount,
          luckDrawList,
        } = data.data;
        const RemainingTimes = canluckDrawCount - hasBennluckDrawNum;
        this.setState({
          loading: false,
          allLuckDrawAmount,
          luckDrawList,
          RemainingTimes,
          canluckDrawCount,
        });
      }
    } catch (error) {
      setTimeout(() => {
        this.setState({
          loading: false,
        });
        Toast.fail("信息获取失败!");
      }, 3000);
    }
  };

  //执行抽奖
  doLuckDraw = async () => {
    const { canluckDrawCount, hasBennluckDrawNum, RemainingTimes } = this.state;
    const token = localStorage.getItem("token");

    if (canluckDrawCount * 1 === 0 || RemainingTimes * 1 <= 0) {
      Toast.fail("剩余抽奖次数 0 ");
      return;
    }
    if (hasBennluckDrawNum * 1 >= canluckDrawCount * 1) {
      Toast.fail("剩余抽奖次数 0");
      return;
    }
    this.setState({
      lotteryIsOver: true,
    });
    try {
      const { data }: any = await doLuckDraw({ token });
      if (data && data.code === "ok") {
        const value = 1 * data.luckyDrawAmount;
        setTimeout(() => {
          this.setState({
            lotteryIsOver: false,
            luckyDrawAmount: value,
            WinPop: true,
          });
          this.getLuckDrawInfo();
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        this.setState({
          lotteryIsOver: false,
        });
        Toast.fail("抽奖次数为0");
      }, 3000);
    }
  };

  addPromoterLuckDrawCount = async () => {
    const token = localStorage.getItem("token");
    await addPromoterLuckDrawCount({ token });
  };

  // 点击,下拉刷新
  handleRefresh = async () => {
    this.setState({ refreshing: true });
    this.addPromoterLuckDrawCount().then((res) => {
      setTimeout(() => {
        this.setState({ refreshing: false });
      }, 500);
    });
  };

  setWinPop = (WinPop: any) => {
    this.setState({
      WinPop,
    });
  };

  render() {
    const {
      loading,
      lotteryIsOver,
      RemainingTimes,
      allLuckDrawAmount,
      luckDrawList,
      luckyDrawAmount,
      WinPop,
      rulePop,
    } = this.state;

    return (
      <div className="align">
        {/* <PullToRefresh
          damping={60}
          indicator={this.state.down ? {} : { deactivate: "上拉可以刷新" }}
          direction={this.state.down ? "down" : "up"}
          refreshing={this.state.refreshing}
          onRefresh={this.handleRefresh}
          distanceToRefresh={50}
          getScrollContainer={() => undefined}
        > */}
        <div>
          <ActivityIndicator
            size="large"
            toast
            animating={loading}
            text="正在加载"
          />
        </div>
        <div>
          <ActivityIndicator
            size="large"
            toast
            animating={lotteryIsOver}
            text="抽奖中..."
          />
        </div>
        <div className="luckDraw">
          <img
            className="luckDrawBgUrl"
            src={luckDrawBgUrl}
            alt={luckDrawBgUrl}
          />
          <img
            className="luckContentUrl"
            src={luckContentUrl}
            alt={luckContentUrl}
          />
          <div
            className="luckinfoContaint"
            style={{ display: WinPop ? "block" : "none" }}
          >
            <LuckInfo
              luckyDrawAmount={luckyDrawAmount}
              doluckDraw={this.doLuckDraw}
            />
          </div>

          <img
            onClick={() => {
              this.setState({
                rulePop: true,
              });
            }}
            className="ruleBtnUrl"
            src={ruleBtnUrl}
            alt="规则按钮"
          />
          <Modal
            //  transparent
            onClose={() => {
              this.setState({
                rulePop: false,
              });
            }}
            popup
            className="ruleUrlModal"
            visible={rulePop}
          >
            <img className="ruleUrl" src={ruleUrl} alt="" />
            <img
              className="closeBtn"
              src={closeBtn}
              alt=""
              onClick={() => {
                this.setState({
                  rulePop: false,
                });
              }}
            />
          </Modal>
          {/* <div style={{textAlign:'center'}}> */}

          <span className="allLuckDrawAmount">
            {`${allLuckDrawAmount || 0} `}
          </span>
          <img className="amountUrl" src={amountUrl} alt="" />
          {/* </div> */}

          <img
            src={doluckDrawUrl}
            alt=""
            onClick={() => {
              this.doLuckDraw();
            }}
            className="luckDrawBtn"
          />
          <div className="RemainingTimes">
            <p>{`${(RemainingTimes > 0 && RemainingTimes) || 0}`}</p>
          </div>
          <img className="countUrl" src={countUrl} alt="" />
        </div>
        <div className="LuckDrawList">
          <div className="containt"></div>
          <img className="listLeftUrl" src={listLeftUrl} alt="" />
          <img className="listRightUrl" src={listRightUrl} alt="" />
          <img className="listBottomUrl" src={listBottomUrl} alt="" />
          <LuckDrawList luckDrawList={luckDrawList} />
        </div>

        {/* </PullToRefresh> */}
      </div>
    );
  }
}
export default luckDraw;
