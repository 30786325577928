import React from "react";
import moment from "moment";
import {
  Card,
  WingBlank,
  WhiteSpace,
  Flex,
  Calendar,
  List,
  ActivityIndicator,
  Modal,
  Toast,
} from "antd-mobile";
import {
  promoGameData,
  promoDataList,
  reward1yuan,
  getPromoterInfo,
} from "../../api";
import { getToken, getCooperatorId } from "../../utils/auth";

import "./index.css";
import TabPlat from "../../component/tabPlat";

declare let WeixinJSBridge: any;

interface pageState {
  promoDataList: any;
  config: any;
  en: boolean;
  show: boolean;
  animating: boolean;
  profitGameList: any;
  imgUrl: any;
  avatarImgUrl: any;
  modalImgUrl: any;
  pageRequestCount: number;
  modalVisible: boolean;
  yinghuaImgUrl: any;
  rewardImgUrl: any;
  cooperatorId: any;
  tabType: any; //平台类型
  ksAuth: any;
}
const now = new Date();
class Profit extends React.Component<any, pageState> {
  originbodyScrollY = document.getElementsByTagName("body")[0].style.overflowY;
  constructor(props: any) {
    super(props);
    this.state = {
      promoDataList: {},
      config: {},
      show: false,
      en: false,
      animating: false,
      profitGameList: [],
      pageRequestCount: 0,
      imgUrl: require("../../assets/empty.png"),
      avatarImgUrl: require("../../assets/avatar.png"),
      modalImgUrl: require("../../assets/wechat.png"),
      modalVisible: false,
      yinghuaImgUrl: require("../../assets/yinghua.jpg"),
      rewardImgUrl: require("../../assets/reward1yuan.png"),
      cooperatorId: 0,
      tabType: "CPA",
      ksAuth: 1,
    };
  }
  pageValue = {
    endTime: moment().format("YYYY-MM-DD"),
    startTime: moment().subtract(30, "days").format("YYYY-MM-DD"),
    profitGameId: "",
  };
  renderBtn(zh: string, config: any) {
    let startTime = this.pageValue.startTime;
    let endTime = this.pageValue.endTime;
    return (
      <List.Item
        arrow="horizontal"
        onClick={() => {
          document.getElementsByTagName("body")[0].style.overflowY = "hidden";
          this.setState({
            show: true,
            // config
          });
        }}
      >
        {this.state.pageRequestCount > 1 ? startTime + " 至 " + endTime : zh}
      </List.Item>
    );
  }
  componentDidMount() {
    this.getPromoGameData();
    this.getPromoDataList();
    let cooperatorId = getCooperatorId();
    this.setState({ cooperatorId: cooperatorId });
    this.promoterInfo();
  }
  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  };

  // 推广过的游戏列表
  getPromoGameData = async () => {
    const { tabType } = this.state;
    let token = getToken();
    let { data } = await promoGameData({ token, tabType });
    if (data.code === "ok") {
      this.setState({ profitGameList: data.data });
    }
  };
  // 收益游戏列表
  getPromoDataList = async () => {
    const { tabType } = this.state;
    let token = getToken();
    this.setState({ animating: true });
    setTimeout(() => {
      this.setState({ animating: false });
    }, 5000);
    const { data } = await promoDataList({
      token,
      dateStart: this.pageValue.startTime,
      dateEnd: this.pageValue.endTime,
      appid: this.pageValue.profitGameId,
      tabType,
    });
    if (data.code === "ok") {
      this.setState({
        promoDataList: data.data,
        animating: false,
        pageRequestCount: this.state.pageRequestCount + 1,
      });
    }
  };
  onCancel = () => {
    document.getElementsByTagName("body")[0].style.overflowY =
      this.originbodyScrollY;
    this.setState({
      show: false,
    });
  };
  // 日历确认框
  onConfirm = (startTime: any, endTime: any) => {
    let start = moment(startTime).format("YYYY-MM-DD");
    let end = moment(endTime).format("YYYY-MM-DD");
    document.getElementsByTagName("body")[0].style.overflowY =
      this.originbodyScrollY;
    this.setState({
      show: false,
    });
    this.pageValue.startTime = start;
    this.pageValue.endTime = end;
    this.getPromoDataList();
  };
  // 游戏选择器change事件
  selectChange = (e: any) => {
    this.pageValue.profitGameId = e.target.value;
    this.getPromoDataList();
  };
  cashOut = () => {
    this.setState({ modalVisible: true });
  };
  // 关闭弹框
  onClose = () => {
    this.setState({ modalVisible: false });
  };
  // 点击查看
  handleCheck = (videoId: any) => {
    window.location.href = `sslocal://microapp?version=v2&app_id=ttf1ed87506ed645bb&scene=016002&version_type=current&start_page=pages%2Findex%2Findex%3FvideoId%3D${videoId}`;
  };
  handleRewardBtnClick = async () => {
    let token = getToken();
    let { data } = await reward1yuan({ token });
    if (data.code === "ok") {
      console.log(data);
      if (typeof WeixinJSBridge !== "undefined") {
        WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          data.data,
          function (res: any) {
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              Toast.success("打赏成功!", 2);
            }
          }
        );
      }
    }
  };

  isWeixin = () => {
    return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
  };
  //不同的平台类型的请求
  tabChange = (tab: any, index: any) => {
    //index 0表示CPA 1表示CPS
    const tabTypeArr = ["CPA", "CPS"];
    const tabType = tabTypeArr[index];
    // if (index === 0) {
    //   this.setState({ yinghuaImgUrl: require("../../assets/yinghua.jpg") });
    // } else {
    //   this.setState({ yinghuaImgUrl: require("../../assets/ks.jpeg") });
    // }
    this.pageValue.profitGameId = "";
    this.setState({ tabType }, () => {
      this.getPromoGameData();
      this.getPromoDataList();
    });
  };
  // 用户信息
  promoterInfo = async () => {
    let token = getToken();
    let { data } = await getPromoterInfo({ token });
    if (data.code === "ok") {
      const { ksAuth } = data.data;
      this.setState({ ksAuth });
    }
  };

  render() {
    const {
      profitGameList,
      imgUrl,
      avatarImgUrl,
      promoDataList,
      modalVisible,
      modalImgUrl,
      yinghuaImgUrl,
      rewardImgUrl,
      cooperatorId,
    } = this.state;
    const { profitGameId, startTime, endTime } = this.pageValue;

    return (
      <div className="profit">
        <div>
          <WingBlank size="lg">
            <Flex>
              <Flex.Item style={{ flex: 2 }}>
                <select
                  value={profitGameId}
                  name=""
                  className="profitSelect"
                  placeholder="请选择游戏"
                  onChange={this.selectChange}
                >
                  <option value="">请选择游戏</option>
                  {profitGameList.map((item: any) => (
                    <option value={item.appid} key={item.appid}>
                      {item.gameName}
                    </option>
                  ))}
                </select>
              </Flex.Item>
              <Flex.Item style={{ flex: 1 }}>
                <img
                  src={rewardImgUrl}
                  alt=""
                  className="rewardBtn"
                  onClick={this.handleRewardBtnClick}
                  hidden={!this.isWeixin()}
                ></img>
              </Flex.Item>
            </Flex>
            <WhiteSpace size="lg" />
            <Card
              style={{ backgroundColor: "#222440" }}
              className="profitDateCard"
            >
              <Card.Header
                title={this.renderBtn(`${startTime}-${endTime}`, {
                  defaultValue: [new Date(+now), new Date(+now - 518400000)],
                })}
                extra={
                  <span className="cashOut" onClick={this.cashOut}>
                    提现
                  </span>
                }
              />
              <Calendar
                {...this.state.config}
                visible={this.state.show}
                onCancel={this.onCancel}
                onConfirm={this.onConfirm}
                defaultDate={now}
              />
              <Card.Body>
                <Flex>
                  {/* <Flex.Item> <div>{promoDataList.totalVisits ? promoDataList.totalVisits : 0}</div><div>访问次数</div></Flex.Item> */}
                  <Flex.Item>
                    {" "}
                    <div>
                      {promoDataList.totalDAU ? promoDataList.totalDAU : 0}
                    </div>
                    <div>访问人数</div>
                  </Flex.Item>
                  <Flex.Item>
                    {" "}
                    <div>
                      ¥{" "}
                      {promoDataList.totalIncome
                        ? promoDataList.totalIncome
                        : 0}
                    </div>
                    <div>预估佣金</div>
                  </Flex.Item>
                </Flex>
              </Card.Body>
            </Card>
            <WhiteSpace size="lg" />
            <div className="TabPlatContainer">
              <TabPlat tabChange={this.tabChange} />
            </div>
          </WingBlank>
          <div className="detailTitle">收益详情</div>
          {/* 空数据图片 */}
          {this.state.animating ? (
            <ActivityIndicator
              size="large"
              toast
              text="正在加载"
              animating={this.state.animating}
            />
          ) : (
            <div
              className="emptyImg"
              style={{
                display:
                  promoDataList.rows && promoDataList.rows.length > 0
                    ? "none"
                    : "block",
              }}
            >
              <img src={imgUrl} alt="" />
              <div>~ 暂无相关数据 ~</div>
            </div>
          )}
          <WingBlank size="lg">
            <WhiteSpace size="lg" />
            <div>
              {promoDataList.rows &&
                promoDataList.rows.map((item: any, index: any) => (
                  <Card
                    style={{ backgroundColor: "#ededed" }}
                    className="propfitCard"
                    key={index}
                  >
                    <Card.Header
                      title={
                        <span>
                          {"推广者: "}
                          <img
                            src={item.avatarUrl}
                            alt=""
                            style={{
                              width: 20,
                              height: 20,
                              verticalAlign: "middle",
                              borderRadius: "50%",
                            }}
                          />
                          {item.nickName}
                        </span>
                      }
                      extra={<span>{item.status}</span>}
                    />
                    <Card.Body>
                      <div className="profit-card-body">
                        <div className="icon">
                          <img
                            src={item.gameIcon ? item.gameIcon : avatarImgUrl}
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <div className="content-title">{item.hideGame === 0 ? item.gameName : "****" + item.gameName.substring(4)}</div>
                          <div className="content-details">
                            计费方式:<span>{item.cooperationMode}</span>
                          </div>
                          <div className="content-details">
                            访问人数:<span>{item.DAU || 0}</span>
                          </div>
                          {item.cooperationMode === "CPS" && (
                            <div className="content-details">
                              广告曝光:<span>{item.shows || 0}</span>
                            </div>
                          )}
                        </div>
                        <div className="profit-block">
                          {item.cooperationMode === "CPS" ? (
                            <>
                              <div className="profit-block_content">
                                <div className="label">今日预估收益</div>
                                <div className="text">预计 ¥ {item.estimateIncome || 0}</div>
                              </div>
                              <div className="profit-block_content">
                                <div className="label">实际收益</div>
                                <div className="text">¥ {item.realIncome || 0}{!item.updated && "（更新中）"}</div>
                              </div>
                            </>
                          ) : (
                            <div className="profit-block_content">
                              <div className="label">预估收益</div>
                              <div className="text" style={{ marginTop: "8px" }}>预计 ¥ {item.income || 0}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Card.Body>
                    <Card.Footer content={
                      <div className="profit-card-footer">
                        <div>发布日期:{item.date}</div>
                        {item.videoId ? (
                          <div className="view" onClick={() => this.handleCheck(item.videoId)}>
                            查看视频
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    } />
                  </Card>
                ))}
            </div>
            <div
              className="foote"
              style={{
                display:
                  promoDataList.rows && promoDataList.rows.length > 0
                    ? "block"
                    : "none",
              }}
            >
              {" "}
              -- 到底啦 --{" "}
            </div>
            <WhiteSpace size="lg" />
          </WingBlank>
          <Modal
            visible={modalVisible}
            transparent
            maskClosable
            onClose={this.onClose}
            footer={[
              {
                text: "Ok",
                onPress: () => {
                  console.log("ok");
                  this.onClose();
                },
              },
            ]}
            style={{ height: "65%", width: "85%" }}
          >
            <div>
              <img
                src={cooperatorId === "19439" ? yinghuaImgUrl : modalImgUrl}
                alt=""
                style={{ width: "80%", height: "85%" }}
              />
              {cooperatorId !== "19439" ? (
                <div>
                  <div>提现+我们的商务妹妹</div>
                  <div> VX：TM1997-r </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}
export default Profit;
