import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Home from '../pages/Home';
import About from '../pages/My/component/about';
import Setting from '../pages/My/component/setting';
import Promote from '../pages/My/component/promote';
import Login from '../pages/Login/index';
import Privacy from '../pages/My/component/privacy';
import Gamedetail from '../pages/Index/component/gameDetail';
import NewGamedetail from "../pages/Index/component/newGameDetail";
import Propaganda from '../pages/Index/component/propaganda';
import Bannerpage from '../pages/Index/component/bannerPage';
import Promoterule from '../pages/My/component/promoteRule';
import Flower from '../pages/Flower';
import CashOut from '../pages/My/component/cashOut';
import CashHistory from '../pages/My/component/cashHistory';
import KSPSP from '../pages/KSPSP';
import { isAuth } from '../utils/auth';

class Routers extends  React.Component{
  render(){
  const isLogin = isAuth()
    return(
        <BrowserRouter>
            <Switch>
              {
                isLogin ? 
                <Switch>
                  <Route path="/" exact render={() => <Redirect to="/home" />} />
                  <Route path="/home" component={Home}></Route>
                  <Route exact path="/my/about" component={About} />
                  <Route exact path="/my/setting" component={Setting} />
                  <Route exact path="/my/promote" component={Promote} />
                  <Route exact path="/my/privacy" component={Privacy} />
                  <Route exact path="/gameDetail" component={Gamedetail} /> 
                  <Route exact path="/newGameDetail" component={NewGamedetail} />
                  <Route exact path="/propaganda" component={Propaganda} />
                  <Route exact path="/bannerPage" component={Bannerpage} />
                  <Route exact path="/my/promoteRule" component={Promoterule} />
                  <Route exact path="/my/cashOut" component={CashOut} />
                  <Route exact path="/my/cashHistory" component={CashHistory} />
                  <Route path="/KSPSP" exact component={KSPSP}></Route>
                  <Route path="/login" render={() => <Redirect to="/home" />}></Route>
                </Switch>
                : 
                <Switch>
                  <Route path="/my/privacy" exact component={Privacy}/>
                  <Route path="/login" exact component={Login}></Route>
                  <Route path="/flower" exact component={Flower}></Route>
                  <Route path="/KSPSP" exact component={KSPSP}></Route>
                  <Redirect from='/*' to="/login"/>
                </Switch>
              }
            </Switch>
          </BrowserRouter>
    )
  }
};

export default Routers
