const TOKEN_NAME = "token";
const PARENTID_NAME = "parentId";
const COOPERATORID_NAME = "cooperatorId";
// 获取 token
const getToken = () => localStorage.getItem(TOKEN_NAME);

// 设置 token
const setToken = (value) => localStorage.setItem(TOKEN_NAME, value);

// 设置代理id
const parentId = (value) => localStorage.setItem(PARENTID_NAME, value);

// 获取代理id
const getParentId = (value) => localStorage.getItem(PARENTID_NAME);

// 删除 token
const removeParentId = () => localStorage.removeItem(PARENTID_NAME);

// 删除 token
const removeToken = () => localStorage.removeItem(TOKEN_NAME);

// 是否登录（有权限）
const isAuth = () => !!getToken();
const UrlName = window.location.href.split("/");

// 设置公司id
const setCooperatorId = (value) =>
  localStorage.setItem(COOPERATORID_NAME, value);

// 获取公司id
const getCooperatorId = (value) => localStorage.getItem(COOPERATORID_NAME);

export {
  getToken,
  setToken,
  removeToken,
  isAuth,
  parentId,
  getParentId,
  removeParentId,
  setCooperatorId,
  getCooperatorId,
  UrlName,
};
