import React from 'react';
import Header from '../../../../component/header'

class About extends React.Component<any>{
  state = {
    imgUrl: require('../../../../assets/avatar.png')
  }
  goBack = () => {
   this.props.history.goBack()
  }

  render(){
    return (
      <div className="about">
        <Header text="关于我们" arrow={true} goBack={ this.goBack} leftContent='返回'></Header>
        <div style={{color:'#fff'}} >
          <div style={{padding:'90px 0 55px', textAlign:'center'}}>
            <img src={this.state.imgUrl} alt="" style={{width:150, height:150}}/>
            <div style={{ margin:'12px 0'}}>抖花花</div>
            <div>当前版本: v1.0.5</div>
          </div>
        </div>
      </div>
    )
  }
}

export default About