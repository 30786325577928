import React from 'react';
import Header from '../../../../component/header';
// import { Modal, Toast, Card } from 'antd-mobile';
import './index.css';

interface pageState {
  
}

class Promoterule extends React.Component<any,pageState>{


  goBack = () => {
   this.props.history.goBack()
  }
  render(){
    return (
      <div className="promoteRule" style={{backgroundColor:'#fff', height:document.body.clientHeight}}>
        <Header text="推广规则" arrow={true} goBack={ this.goBack} leftContent='返回'></Header>
        <div className="tableBox">
          <h3 style={{color:'#000',textAlign:'center',fontWeight:400}}>推广下级抽佣规则</h3>
          <table style={{border:'1px solid #ccc', width:'100%', textAlign:'center'}}>
            <thead >
              <tr>
                <th>直属下级</th>
                <th>月收益标准</th>
                <th>上级抽佣比例</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default Promoterule