import React from 'react';
import Header from '../../../../component/header';
import './index.css'
import { WingBlank } from 'antd-mobile'


class Privacy extends React.Component<any>{
  state = {
    text:''
  }
  componentDidMount(){
    this.setState({
      text:this.props.location.state
    })
  }
  goBack = () => {
    this.props.history.goBack()
    // this.props.history.push('/login')
    // this.props.history.go()
   }
  render(){
    return(
      <div className="privacy">
         <Header text={this.state.text} arrow={true} goBack={ this.goBack} leftContent='返回'></Header>
         <div className="provacyBox">
          <WingBlank size="lg">
            <h3>用户服务协议</h3>
            <div className="provacyInfo">
              <h5> 重要提示：</h5>
              <p>  请您仔细阅读以下条款，并确认您已完全理解本协议之规定，尤其是免除及限制责任的条款、知识产权条款、法律适用及争议解决条款。  若您对本声明或本协议任何条款有异议，请停止注册或使用抖花花平台所提供的全部服务。</p>
              <h5 style={{marginTop:30}}>  一、协议的接受、变更与补充  </h5>
              <ul>
                <li>  1、勾选本协议前选项框并点击“登录”，将视为您签署了本协议，表明您自愿接受本协议全部条款的约束，本协议将构成您与抖花花平台之间具有约束力的法律文件。无论您是进入抖花花平台浏览网页，还是在抖花花平台上发布任何内容，或者是直接或通过各类方式（如站外API引用等）间接使用抖花花平台服务和数据的行为，都将被视作已无条件接受本声明所涉全部内容。  </li>
                <li>    2、抖花花平台有权利对本协议进行修改，协议修改后，抖花花平台将通过在相关页面公告或发送通知等方式公布修改的内容，修改后的协议一经公布即有效的代替原协议。如果您不同意本协议的修改，请立即停止访问或使用本网站或取消已经获得的服务；如果您选择继续访问或使用本网站，则视为您已接受本协议的修改。    </li>
                <li>  3、签署的本协议所列明的条款，并不能完全涵盖您与抖花花平台之间所有的权利和义务。因此，抖花花平台不定期公布的其他声明、规则等均视为本协议之补充协议，为本协议不可分割的组成部分，与本协议具有同等法律效力。  </li>
                <li>  4、如本协议与抖花花平台其它协议条款不一致，以其它协议条款内容为准。  </li>
              </ul>
              <h5>  二、帐号密码与登录  </h5>
              <p>1、帐号的取得</p>
              <ul>
                <li>    (1)如您确认，在您开始登录抖花花平台前，已经具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。   </li>
                <li>     (2)您登录成功后，即成为抖花花平台用户，用户须对在的登录信息的真实性、合法性、有效性承担全部责任。您可自行创建、修改昵称，但用户名和昵称的命名及使用应遵守相关法律法规并符合网络道德，不得冒充他人或恶意注册使人误认；不得利用他人的名义发布任何信息；不得恶意使用注册帐号导致其他用户误认；用户名和昵称中不能含有任何侮辱、诽谤、淫秽或暴力等侵害他人合法权益或违反公序良俗的词语。如您违反前述规定，抖花花平台有权随时限制或拒绝您使用该账号，甚至注销该账号。  </li>
                <li>    (3)用户名的管理  </li>
                <li>
                  <ul>
                    <li>  {"1)"}请勿以党和国家领导人或其他名人的真实姓名、字、号、艺名、笔名、头衔等注册和使用昵称（如确为本人，需要提交相关证据并通过审核方可允许使用）；</li>
                    <li>  {"2)"}请勿注册和使用与其他网友相同、相仿的用户名或昵称；  </li>
                    <li>  {"3)"}请勿注册和使用不文明、不健康的用户名和昵称；  </li>
                    <li>  {"4)"}请勿注册和使用易产生歧义、引起他人误解或带有各种奇形怪状符号的用户名和昵称。  </li>
                    <li>  {"5)"}用户以虚假信息骗取账号名称登录，或账号头像、简介等存在违法和不良信息的，抖花花平台将暂停或注销。  </li>
                  </ul>
                </li>
              </ul>
              <p>  2、请您妥善保管您用户帐号和密码，不要将帐号密码告知他人，因用户原因导致帐号或密码泄露而造成的法律后果由用户负责。同时，用户还应当对以此帐号登录进行的所有活动和事件承担全部后果与法律责任。  </p>
              <p>  3、若您的帐号或密码遗失，可以通过登录信息按照相关步骤找回密码，若您发现账号遭到未授权的使用或存在其他安全漏洞的情况，应立即告知抖花花平台。  </p>
              <h5>  三、用户个人信息  </h5>
              <p> 1、个人信息的保护</p>
              <ul>
                <li>  (1)保护用户个人信息及个人隐私是抖花花平台的基本原则之一。  </li>
                <li>  (2)您的帐号服务过程中，需要填写一些必要的信息。若国家法律法规有特殊规定的，您可能还需要填写真实的身份信息。  </li>
                <li>  (3)我们将尽一切可能采取适当的技术手段，保证您可以访问、更新和更正自己信息或使用我们的服务时提供的其他个人信息，但出于安全性和身份识别。  </li>
                <li>  (4)抖花花平台将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。  </li>
                <li>  (5)未经您的同意，抖花花平台不会向抖花花平台以外的任何公司、组织和个人披露您的个人信息，但法律法规另有规定的除外。  </li>
                <li>  (6)抖花花平台非常重视对未成年人个人信息的保护，若未成年人（尤其是十岁以下）希望得以使用抖花花平台提供的服务，必须得到父母（监护人）的同意。  </li>
                <li>  (7)抖花花平台将通过技术手段、强化内部管理等办法充分保护用户的个人隐私信息，保证不对外公开或向第三方披露或提供用户注册资料及用户在使用网络服务时存储在平台、服务器或数据库的非公开内容和信息，但下列情况除外：  </li>
                <li>
                  <ul>
                    <li>{"1)"} 事先获得用户的授权；</li>
                    <li>{"2)"} 根据有关的法律法规要求；</li>
                    <li>{"3)"} 按照相关政府主管部门和司法机关的要求；</li>
                    <li>{"4)"} 为维护社会公众的利益；</li>
                    <li>{"5)"} 为维护抖花花平台的合法权</li>
                  </ul>
                </li>
              </ul>
            </div>
           </WingBlank>
         </div>
      </div>
    )
  }
}

export default Privacy