import React from "react";
import crypto, { HexBase64BinaryEncoding, Utf8AsciiBinaryEncoding } from "crypto";
import { getDataForKSPSP } from "../../api";

interface pageState {
  ksData: any;
}
class KSPSP extends React.Component<any, pageState> {
  componentDidMount() {
    console.log(this.props.match);
    console.log(this.props.location);
    const appid = this.getQueryString("appid");
    const promoterId = this.getQueryString("promoterId");
    this.getData(appid, promoterId);
  }

  getData = async (appid: any, promoterId: any) => {
    // 时间戳
    let ts = Date.now();
    // 签名
    const sign = crypto
      .createHash("md5")
      .update(`appid=${appid}&promoterId=${promoterId}&ts=${ts}`)
      .digest("hex");
    let { data } = await getDataForKSPSP({
      appid,
      promoterId,
      ts,
      sign,
    });
    console.log(data);
    if (data && data.code === "ok") {
      // 解密
      const key = crypto.createHash("md5").update(appid).digest("hex");
      const iv = `ts:${ts}`;
      const decryptURI = this.aesDecrypt(data.data, key, iv);
      window.location.href = decryptURI;
    }
  };

  aesDecrypt = (data: any, key: any, iv: any) => {
    if (!data) {
      return "";
    }
    iv = iv || "";
    var clearEncoding: Utf8AsciiBinaryEncoding = "utf8";
    var cipherEncoding: HexBase64BinaryEncoding = "base64";
    var cipherChunks = [];
    var decipher = crypto.createDecipheriv("aes-256-cbc", key, iv);
    decipher.setAutoPadding(true);
    cipherChunks.push(decipher.update(data, cipherEncoding, clearEncoding));
    cipherChunks.push(decipher.final(clearEncoding));
    return cipherChunks.join("");
  };

  getQueryString = (name: string) => {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = this.props.location.search.substr(1).match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    }
    return null;
  };

  render() {
    return (
      <div
        className="flower"
        style={{ height: document.body.clientHeight, backgroundColor: "#fff" }}
      >
        <h1
          style={{
            fontSize: "16px",
            textAlign: "center",
            margin: 0,
            color: "#000",
            padding: "10px",
          }}
        >
          抖花花
        </h1>
      </div>
    );
  }
}
export default KSPSP;
