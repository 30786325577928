import React from "react";
import moment from 'moment';
import Header from "../../../../component/header";
import {
  Card,
  Flex,
  WingBlank,
  WhiteSpace,
  List,
  Calendar
} from "antd-mobile";
import "./index.css";
import { getParentId, getToken } from "../../../../utils/auth";
import { promofitTeamData } from "../../../../api/index";

interface pageState {
  teamData: any;
  avatarImgUrl: any;
  show: boolean;
  pageRequestCount:number;
  config:any;
}
const now = new Date();
class Promote extends React.Component<any, pageState> {
  originbodyScrollY = document.getElementsByTagName('body')[0].style.overflowY;
  constructor(props: any) {
    super(props);
    this.state = {
      teamData: {},
      avatarImgUrl: require("../../../../assets/avatar.png"),
      show: false,
      pageRequestCount:0,
      config: {},
    };
  }
  pageValue = {
    endTime:moment().format('YYYY-MM-DD'),
    startTime:moment().subtract(7, 'days').format('YYYY-MM-DD'),
  }
  renderBtn(zh: string, config:any) {
    let startTime = this.pageValue.startTime
    let endTime = this.pageValue.endTime
    return (
      <List.Item arrow="horizontal"
        onClick={() => {
          document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
          this.setState({
            show: true,
            // config
          });
        }}
      >
        {this.state.pageRequestCount > 1 ? startTime + ' 至 ' + endTime : zh}
      </List.Item>
    );
  }
  onCancel = () => {
    document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
    this.setState({
      show: false,
    });
  }
  // 日历确认框
  onConfirm = (startTime: any, endTime: any) => {
    let start = moment(startTime).format('YYYY-MM-DD')
    let end = moment(endTime).format('YYYY-MM-DD')
    document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
    this.setState({
      show: false,
    });
    this.pageValue.startTime = start
    this.pageValue.endTime = end
    this.getPromofitTeamData()
  }
  componentDidMount() {
    this.getPromofitTeamData()
  }
  getPromofitTeamData = async () => {
    let parentId = getParentId();
    let { data } = await promofitTeamData({
      token: getToken(),
      parentId,
      dateStart:this.pageValue.startTime,
      dateEnd:this.pageValue.endTime
    });
    if (data.code === "ok") {
      this.setState({ teamData: data.data });
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    const { teamData, avatarImgUrl } = this.state;
    const { startTime, endTime } = this.pageValue
    const teamList =
      teamData.result &&
      teamData.result.map((item: any, index: any) => (
        <Card
          style={{ backgroundColor: "#ededed" }}
          className="propfitCard"
          key={index}
        >
          <Card.Header
            title={
              <span>
                {"推广者: "}
                {item.nickName}
              </span>
            }
            extra={<span>{item.status}</span>}
          />
          <Card.Body>
            <div className="profit-card-body">
              <div className="icon">
                <img
                  src={item.gameIcon ? item.gameIcon : avatarImgUrl}
                  alt=""
                />
              </div>
              <div className="content">
                <div className="content-title">{item.gameName}</div>
                <div className="content-details">
                  计费方式:<span>{item.cooperationMode}</span>
                </div>
                <div className="content-details">
                  访问人数:<span>{item.DAU || 0}</span>
                </div>
                {item.cooperationMode === "CPS" && (
                  <div className="content-details">
                    广告曝光:<span>{item.shows || 0}</span>
                  </div>
                )}
              </div>
              <div className="profit-block">
                {item.cooperationMode === "CPS" ? (
                  <>
                    <div className="profit-block_content">
                      <div className="label">今日预估收益</div>
                      <div className="text">预计 ¥ {item.estimateIncome || 0}</div>
                    </div>
                    <div className="profit-block_content">
                      <div className="label">实际收益</div>
                      <div className="text">¥ {item.realIncome || 0}{!item.updated && "（更新中）"}</div>
                    </div>
                  </>
                ) : (
                  <div className="profit-block_content">
                    <div className="label">预估收益</div>
                    <div className="text" style={{ marginTop: "8px" }}>预计 ¥ {item.income || 0}</div>
                  </div>
                )}
              </div>
            </div>
          </Card.Body>
          <Card.Footer content={<div>发布日期:{item.publishDate}</div>} />
        </Card>
      ));
    return (
      <div className="promote">
        <Header
          text="我的团队"
          arrow={true}
          goBack={this.goBack}
          leftContent="返回"
        ></Header>
        <WingBlank size="lg">
          <WhiteSpace size="lg" />
          <Card>
            <Card.Body>
              <div className="teamInfo">
                <Flex>
                  <Flex.Item>
                    <p>团队收益</p>
                    <p>¥ {teamData.totalProfit || 0}</p>
                  </Flex.Item>
                  <Flex.Item>
                    <p>账号</p>
                    <p>{teamData.totalUser || 0}</p>
                  </Flex.Item>
                  <Flex.Item>
                    <p>视频数</p>
                    <p>{teamData.totalVideo || 0}</p>
                  </Flex.Item>
                </Flex>
              </div>
            </Card.Body>
          </Card>
          <WhiteSpace size="lg" />
        </WingBlank>
        <WingBlank size="lg">
          <WhiteSpace size="lg" />
          <div className="title">团队收益</div>
          <div>
          {this.renderBtn(`${startTime} - ${endTime}`, { defaultValue: '' })}
          </div>
          <Calendar
                  {...this.state.config}
                  visible={this.state.show} 
                  onCancel={this.onCancel}
                  onConfirm={this.onConfirm}
                  defaultDate={now}
                />
          <WhiteSpace size="lg" />
        </WingBlank>
        <div className="list">
          <WingBlank size="lg">
            <WhiteSpace size="lg" />
            {teamList}
            <WhiteSpace size="lg" />
          </WingBlank>
        </div>
      </div>
    );
  }
}

export default Promote;
