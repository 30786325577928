import React from 'react';
import Header from '../../../../component/header';
import './index.css'
import { List, WingBlank, Modal } from 'antd-mobile';
import { removeToken, getToken, removeParentId, getParentId } from '../../../../utils/auth'
import { logout } from '../../../../api'
const Item = List.Item;


class Setting extends React.Component<any>{
  componentDidMounted(){

  }
  goBack = () => {
   this.props.history.goBack()
  }
  logout = async () => {
    Modal.alert('提示', '确定要退出登录吗?', [
      { text: '取消', onPress: () => console.log('cancel') },
      { text: '确定', onPress: async () => {
        let token = getToken()
        let {data} = await logout({token})
        if(data.code === 'ok'){
         removeToken()
         removeParentId()
         let parentId = getParentId()
         this.props.history.push(`/login?parentId=${parentId}`)
         window.location.reload()
        }
      } },
    ])
  }
  render(){
    return (
      <div className="setting">
        <Header text="系统设置" arrow={true} goBack={ this.goBack} leftContent='返回'></Header>
        <WingBlank>
          <List>
            <Item
              arrow="horizontal"
              style={{ backgroundColor: '#141626', border: 'none' }}
              onClick={() => {this.props.history.push({pathname:'/my/privacy', state:'服务协议'})}}
            >
              <span style={{ fontSize: 16 , color: '#fff' }}>服务协议</span>
            </Item>
            <Item
              arrow="horizontal"
              style={{ backgroundColor: '#141626', border: 'none' }}
              onClick={() => {this.props.history.push({pathname:'/my/privacy', state:'隐私政策'})}}
            >
              <span style={{ fontSize: 16, color:'#fff' }}>隐私政策</span>
            </Item>
            <Item
              arrow="horizontal"
              style={{ backgroundColor: '#141626', border: 'none' }}
              onClick={this.logout}
            >
              <span style={{ fontSize: 16 , color: '#fff' }}>退出登录</span>
            </Item>
        </List>
       </WingBlank>
      </div>
    )
  }
}

export default Setting