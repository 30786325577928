import React from "react";
import "./index.css";
import {
  List,
  WingBlank,
  WhiteSpace,
  Card,
  Flex,
  Modal,
  ActivityIndicator,
  Toast,
} from "antd-mobile";
import Header from "../../component/header";
import { getPromoterInfo, getPromofitData, sendCode } from "../../api";
import {
  getToken,
  parentId,
  getParentId,
  setCooperatorId,
} from "../../utils/auth";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
const Item = List.Item;

interface pageState {
  promoterInfo: any;
  imgUrl: any;
  userPromofit: any;
  modalVisible: boolean;
  inviteVisible: boolean;
  modalImgUrl: any;
  inviteImgUrl: any;
  qrUrl: any;
  image: any;
  animating: any;
  douhhImgUrl: any;
  openId: any;
}
class My extends React.Component<any, pageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      promoterInfo: {
        avatar: "",
        nickName: "",
        parentId: "",
        phone: "",
        promoterId: "",
      },
      userPromofit: {},
      modalVisible: false,
      inviteVisible: false,
      imgUrl: require("../../assets/avatar.png"),
      modalImgUrl: require("../../assets/wechat.png"),
      inviteImgUrl: require("../../assets/promote.jpeg"),
      douhhImgUrl: require("../../assets/douhh.png"),
      qrUrl: "", // value参数为生成二维码的链接
      image: "",
      animating: true,
      openId: "",
    };
  }
  is_weixin = (function () {
    return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
  })();
  componentDidMount() {
    this.promofitData();
    this.promoterInfo();
    const code = this.getUrlParam("code");
    if (code) {
      const promoterId = getParentId();
      this.sendCodeData(code, promoterId);
    }
  }
  sendCodeData = async (code: any, promoterId: any) => {
    let { data } = await sendCode({ code, promoterId });
    if (data.code === "ok") {
      this.setState({ openId: data.data });
    }
  };
  // 用户信息
  promoterInfo = async () => {
    let token = getToken();
    let { data } = await getPromoterInfo({ token });
    if (data.code === "ok") {
      this.setState({
        promoterInfo: data.data,
        qrUrl: `http://www.douhh.com/login?inviteCode=${data.data.inviteCode}`,
      });
      parentId(data.data.promoterId);
      setCooperatorId(data.data.cooperatorId);
    }
  };
  // 用户收益数据
  promofitData = async () => {
    const token = getToken();
    let promoterId = getParentId();
    let { data } = await getPromofitData({ promoterId,token });

    if (data.code === "ok") {
      this.setState({ userPromofit: data.data });
    }
  };
  // 提现
  cashOut = () => {
    if (!this.is_weixin) {
      this.setState({ modalVisible: true });
    } else {
      if (this.state.openId || this.state.promoterInfo.openid) {
        this.props.history.push("/my/cashOut");
      } else {
        //H5页面的要跳转的地址
        let url = encodeURIComponent("http://www.douhh.com/home/my");
        let time = Date.now();
        //公众号的appid,
        let link =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf5b297eae2c1790c&redirect_uri=" +
          url +
          "&response_type=code&scope=snsapi_userinfo&state=" +
          time +
          "#wechat_redirect";
        //这个是授权重定向,授权成功后,地址链接上会拼接code
        window.location.replace(link);
      }
    }
  };
  // 获取用户点击允许后地址栏携带的参数
  getUrlParam(paramName: string) {
    let url = window.location.href;
    let oRegex = new RegExp("[?&]" + paramName + "=([^&]+)", "i");
    let oMatch = oRegex.exec(url);
    if (oMatch && oMatch.length > 1) {
      return decodeURI(oMatch[1]);
    } else {
      return "";
    }
  }
  // 关闭弹框
  onClose = () => {
    this.setState({ modalVisible: false });
  };
  // 关闭弹框
  inviteClose = () => {
    this.setState({ inviteVisible: false }, () => {
      this.setState({ image: "" });
    });
  };
  // 显示邀请图片
  showInviteImg = () => {
    let userPromo = parseFloat(this.state.userPromofit.promoprofit);
    if (userPromo > 100) {
      this.setState({ inviteVisible: true }, () => {
        let canvasEle: any = this.refs.canvasImage; //获取元素
        html2canvas(canvasEle, {}).then((canvas: any) => {
          this.setState({
            image: canvas.toDataURL("image/png"),
            animating: false,
          });
        });
      });
    } else {
      Toast.info("游戏推广收益达到100才能邀请下级", 2);
    }
  };
  render() {
    const {
      promoterInfo,
      imgUrl,
      modalVisible,
      inviteVisible,
      inviteImgUrl,
      userPromofit,
      douhhImgUrl,
    } = this.state;

    return (
      <div className="my">
        <Header text="个人中心" arrow={false}></Header>
        <div className="myInfo">
          <div className="myInfoLeft">
            <img
              src={promoterInfo.avatar ? promoterInfo.avatar : imgUrl}
              alt=""
            />
          </div>
          <div className="myInfoCenter">
            <p>{promoterInfo.nickName}</p>
            <p>手机号:{promoterInfo.phone} </p>
          </div>
          <div className="myInfoRight">
            <button onClick={this.showInviteImg}>邀请</button>
          </div>
        </div>
        <WingBlank size="lg">
          <WhiteSpace size="lg" />
          <Card>
            <Card.Body>
              <div className="getCash">
                <div>
                  <div>可提现金额</div>
                  <div>{userPromofit.amount || 0}</div>
                </div>
                <div>
                  <button onClick={this.cashOut}>提现</button>
                </div>
              </div>
              <div className="showCash">
                <Flex>
                  <Flex.Item>
                    <p>总收益(元)</p>
                    <p>{userPromofit.profit || 0}</p>
                  </Flex.Item>
                  <Flex.Item>
                    <p>游戏推广收益(元)</p>
                    <p>{userPromofit.promoprofit || 0}</p>
                  </Flex.Item>
                  <Flex.Item>
                    <p>活动收益(元)</p>
                    <p>{userPromofit.activityIncome || 0}</p>
                  </Flex.Item>
                  <Flex.Item>
                    <p>推广下级佣金(元)</p>
                    <p>{userPromofit.lowerIncome || 0}</p>
                  </Flex.Item>
                  <Flex.Item>
                    <p>出海收益(元)</p>
                    <p>{userPromofit.overseasIncome || 0}</p>
                  </Flex.Item>
                </Flex>
              </div>
            </Card.Body>
          </Card>
          <WhiteSpace size="lg" />
        </WingBlank>
        <WingBlank>
          <List>
            <Item
              onClick={() => {
                this.props.history.push("/my/promote");
              }}
              arrow="horizontal"
            >
              <i className="iconfont icon">&#xe607;</i>
              <span>推广下级</span>
            </Item>
            <Item
              onClick={() => {
                this.props.history.push("/my/about");
              }}
              arrow="horizontal"
            >
              <i className="iconfont">&#xe63f;</i>
              <span>关于我们</span>
            </Item>
            <Item
              onClick={() => {
                this.props.history.push("/my/setting");
              }}
              arrow="horizontal"
            >
              <i className="iconfont icon">&#xe60c;</i>
              <span>系统设置</span>
            </Item>
          </List>
          {/* 个人中心底部文字 */}
          <div className="foot">
            <div>抖花花</div>
            <div>让视频变得更简单</div>
            <div>v1.0.5</div>
          </div>
        </WingBlank>
        <Modal
          visible={modalVisible}
          transparent
          maskClosable
          onClose={this.onClose}
          style={{ height: "35%", width: "90%" }}
        >
          <div>
            <p className="pstyle">关注公众微信号 : 抖花花</p>
            <p className="pstyle">并在公众号内进行操作! 谢谢!</p>
            <div style={{ width: "95%", margin: "auto", marginTop: 20 }}>
              <img src={douhhImgUrl} alt="" style={{ width: "100%" }} />
            </div>
          </div>
        </Modal>
        <Modal
          visible={inviteVisible}
          maskClosable
          transparent
          closable
          onClose={this.inviteClose}
          style={{ height: "100%", width: "100%" }}
          className="inviteModal"
        >
          {this.state.image ? (
            this.state.animating ? (
              <ActivityIndicator
                size="large"
                toast
                text="正在加载"
                animating={this.state.animating}
              />
            ) : (
              <div className="createImg">
                <img
                  src={this.state.image}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
                <div className="text">长按图片保存到相册</div>
              </div>
            )
          ) : (
            <div className="downLoadImg" ref="canvasImage">
              <div
                className="inviteImg"
                style={{ backgroundImage: `url(${inviteImgUrl})` }}
              >
                <QRCode
                  className="image"
                  value={this.state.qrUrl} //value参数为生成二维码的链接
                  size={120} //二维码的宽高尺寸
                  fgColor="#000000" //二维码的颜色
                />
              </div>
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

export default My;
