import React  from 'react';

class Flower extends React.Component<any>{

  render(){
    return(
      <div className="flower" style={{height:document.body.clientHeight, backgroundColor:'#fff'}}>
        <h1 style={{fontSize:"16px", textAlign:'center',margin:0, color:'#000', padding:'10px'}}>抖花花</h1>
        <p style={{color:'#3c3c3c', padding:'0 20px'}}>向日葵（学名：Helianthus annuus L.）：是菊科、向日葵属的植物。因花序随太阳转动而得名。一年生草本，高1-3.5米，最高可达9米。茎直立，圆形多棱角，质硬被白色粗硬毛。广卵 ，花序边缘生中性的黄色舌状花，不结实。花序中部为两性管状花，棕色或紫色，能结实。矩卵形瘦果，果皮木质化，灰色或黑色，称葵花籽。
        </p>
        <p style={{color:'#3c3c3c', padding:'0 20px'}}>兰花（学名：Cymbidium ssp.）：是单子叶植物纲、兰科、兰属植物通称,中国人历来把兰花看做是高洁典雅的象征，并与“梅、竹、菊”并列，合称“四君子”。通常以“兰章”喻诗文之美，以“兰交”喻友谊之真。也有借兰来表达纯洁的爱情，“气如兰兮长不改，心若兰兮终不移”、“寻得幽兰报知己，一枝聊赠梦潇湘”。1985年5月兰花被评为中国十大名花之四
        </p>

        <img src="https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=320188414,720873459&fm=26&gp=0.jpg" alt="" style={{width:'100%', height:'200px'}}/>
        <img src="https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=1847766668,481817220&fm=26&gp=0.jpg" alt="" style={{width:'100%', height:'200px'}}/>
        <img src="https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=2381646507,2235303527&fm=26&gp=0.jpg" alt="" style={{width:'100%', height:'200px'}}/>
        <img src="https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=3938443184,760630898&fm=26&gp=0.jpg" alt="" style={{width:'100%', height:'200px'}}/>
        <img src="https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1851907827,1044882010&fm=26&gp=0.jpg" alt="" style={{width:'100%', height:'200px'}}/>
        <img src="https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=2168389847,2385675133&fm=26&gp=0.jpg" alt="" style={{width:'100%', height:'200px'}}/>
        <img src="https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=1977244546,241688681&fm=26&gp=0.jpg" alt="" style={{width:'100%', height:'200px'}}/>
        <img src="https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2726463082,758767823&fm=26&gp=0.jpg" alt="" style={{width:'100%', height:'200px'}}/>
        <img src="https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=1096475703,2786322276&fm=26&gp=0.jpg" alt="" style={{width:'100%', height:'200px'}}/>
        <img src="https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=1060645887,972679809&fm=26&gp=0.jpg" alt="" style={{width:'100%', height:'200px'}}/>
        <img src="https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3689023593,1085314961&fm=26&gp=0.jpg" alt="" style={{width:'100%', height:'200px'}}/>
        <img src="https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2370343130,4257779240&fm=11&gp=0.jpg" alt="" style={{width:'100%', height:'200px'}}/>
        <img src="https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3035559371,2990818188&fm=26&gp=0.jpg" alt="" style={{width:'100%', height:'200px'}}/>
        <div style={{fontSize:14, color:'#d0d0d0', textAlign:'center', margin:'50px auto'}} onClick={()=> window.location.href = 'http://beian.miit.gov.cn/'}>闽ICP备15001614号-4</div>
      </div>
    )
  }
}
export default Flower