import React from 'react';
import Header from '../../../../component/header'
import { WingBlank } from 'antd-mobile'
import './index.css'

class Propaganda extends React.Component<any>{
  goBack = () => {
    this.props.history.goBack()
   }
  render(){
    return(
      <div>
        <Header text="抖花花" arrow={true} goBack={ this.goBack}></Header>
        <WingBlank size="lg">
          <div className="propagandaBox">
            <h4>各位抖花花会员</h4>
            <p >抖花花使命和价值观是希望帮助更多的短视频创作者提供变现产品，能够轻松创业，通过自己的努力，多一些收入改善生活！希望各个团队要多正向宣传抖花花使命和价值观。</p>
            <ul>
              <li>为了更好地体现该理念，接下来，请各位会员要严格遵守抖花花宣传规范：</li>
              <li>1、	严禁宣传诸如一夜暴富、躺赚、日入过万、月入百万、年入千万等话术；</li>
              <li>2、	严禁夸大宣传，使用不当手段去宣传；</li>
              <li>3、	严禁公开晒佣金制度；</li>
              <li>4、	严禁公开晒大额收益与大额提现截图或视频（收益超过3000以上都不要再晒了）；</li>
            </ul>
            <p >同时各位会员自建的宣传网站、公众号也要按照以上规范进行集中整改，严格按照平台规范进行合理宣传！各个团队务必重视，如有发现不作为的会员，公司将依照抖花花用户运营规范做相应处理！让我们共同维护一个良性健康的宣传推广环境！让短视频变现更简单，让大家生活更美好！</p>

            <h4>——抖花花相关性标准说明——</h4>
            <p>一、介绍要求：</p>
            <ol>介绍要求禁止诱导行为；</ol>
            <ol>请勿恶意诱导点赞/评论/关注/看完/分享等；</ol>
            <ol>禁止欺骗行为；</ol>
            <ol>请勿利用其他游戏推广游戏禁止全篇兑换码；</ol>
            <ol>描述兑换码等游戏礼包如何获取的篇幅占比不能超过总篇幅的三分之一；</ol>
            <ol>视频主体内容和推广任务完全无关，强行植入推广任务；</ol>
            <ol>禁止套用与游戏无关的剧情来推广游戏切记不要说错/写错推广游戏的名称切记不要使用其他平台的推广游戏视频；</ol>
            <p>二、画面要求：</p>
            <ol>-必须出现推广游戏的画面；</ol>
            <ol>-音乐/舞蹈/手工等以推广周边/衍生品形式推广游戏的可以不出现游戏画面，比如翻唱某游戏主题曲；</ol>
            <p>三、相关性标准说明：</p>
            <ol>过总篇幅的三分之一；</ol>
            <ol>视频主体内容和推广任务完全无关，强行植入推广任务；</ol>
            <ol>禁止套用与游戏无关的剧情来推广游戏切记不要说错/写错推广游戏的名称- 切记不要使用其他平台的推广游戏视频；</ol>
            <p>四、画面要求：</p>
            <ol>必须出现推广游戏的画面；</ol>
            <ol>音乐/舞蹈/手工等以推广周边/衍生品形式推广游戏的可以不出现游戏画面，比如翻唱某游戏主题曲- 不使用其他的游戏素材推广游戏；</ol>
            <ol>禁止在视频中出现无关分屏或者用干扰物遮挡主要游戏画面；</ol>
            <p>五、音频要求：</p>
            <ol>禁止出现与推广内容完全无关的音频</ol>
          </div>
        </WingBlank>
      </div>

    )
  }
}

export default Propaganda