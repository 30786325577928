import React from "react";
import Header from "../../../../component/header";
import { WingBlank } from 'antd-mobile'
import { getParentId, getToken } from "../../../../utils/auth";
import { getPromotCashHistory } from "../../../../api/index";

interface pageState {}
class cashHistory extends React.Component<any, pageState> {
  state = {
    history:[]
  };
  componentDidMount() {
    this.promotCashHistory()
  }
  promotCashHistory = async () => {
    const parentId = getParentId();
    let {data} = await getPromotCashHistory({ parentId, token: getToken() });
    if (data.code === "ok") {
      this.setState({history:data.data})
    }
  };
  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    const { history } = this.state
    return (
      <div className="cashHistory">
        <Header text="日常提现" arrow={true} goBack={this.goBack} leftContent="返回"></Header>
        <WingBlank size='lg'>
        {history.map((item:any, index) => (
            <p style={{padding:'5px 15px'}} key={index}>{index + 1} {item.date}, 运营帮提 {item.settled} 元</p>
        ))}
        </WingBlank>
      </div>
    );
  }
}

export default cashHistory;
