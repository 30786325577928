import React from "react";
import Header from "../../../../component/header";
import { Modal, WingBlank, Icon } from "antd-mobile";
import { getPromofitData } from "../../../../api/index";
import { getParentId, getCooperatorId, getToken } from "../../../../utils/auth";
import "./index.css";

interface pageState {
  modalImgUrl: any;
  cashImgUrl: any;
  modalVisible: boolean;
  userPromofit: any;
  yinghuaImgUrl: any;
  cooperatorId: any;
}
class CashOut extends React.Component<any, pageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      modalImgUrl: require("../../../../assets/wechat.png"),
      cashImgUrl: require("../../../../assets/cash.png"),
      modalVisible: false,
      userPromofit: {},
      yinghuaImgUrl: require("../../../../assets/yinghua.jpg"),
      cooperatorId: 0,
    };
  }
  componentDidMount() {
    this.promofitData();
    let cooperatorId = getCooperatorId();
    this.setState({ cooperatorId: cooperatorId });
  }
  goBack = () => {
    this.props.history.goBack();
  };
  promofitData = async () => {
    let promoterId = getParentId();
    let { data } = await getPromofitData({ promoterId, token: getToken() });
    if (data.code === "ok") {
      this.setState({ userPromofit: data.data });
    }
  };

  onClose = () => {
    this.setState({ modalVisible: false });
  };
  cashOut = () => {
    this.setState({ modalVisible: true });
  };
  render() {
    const {
      modalImgUrl,
      modalVisible,
      cashImgUrl,
      userPromofit,
      yinghuaImgUrl,
      cooperatorId,
    } = this.state;

    return (
      <div className="cashOutPage">
        <Header
          text="提现"
          arrow={true}
          goBack={this.goBack}
          leftContent="返回"
        ></Header>
        <WingBlank size="lg">
          <div className="box">
            <img src={cashImgUrl} alt="" />
            <p>可提现余额 (元)</p>
            <p>{userPromofit.amount || 0}</p>
            <button className="getCash" onClick={this.cashOut}>
              立即提现
            </button>
          </div>
        </WingBlank>
        <div
          className="cashRecord"
          onClick={() => this.props.history.push("/my/cashHistory")}
        >
          <p>提现记录</p>
          <Icon type="right" size="md" />
        </div>
        <Modal
          visible={modalVisible}
          transparent
          maskClosable
          onClose={this.onClose}
          footer={[
            {
              text: "Ok",
              onPress: () => {
                console.log("ok");
                this.onClose();
              },
            },
          ]}
          style={{ height: "70%", width: "85%" }}
        >
          <div>
            <img
              src={cooperatorId === "19439" ? yinghuaImgUrl : modalImgUrl}
              alt=""
              style={{ width: "80%", height: "85%" }}
            />
            {cooperatorId !== "19439" ? (
              <div>
                <div>提现+我们的商务妹妹</div>
                <div> VX：TM1997-r </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default CashOut;
