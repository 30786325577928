import { Card, Toast } from "antd-mobile";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Header from "../../component/header";
import { getPromoterInfo, getOverseasProfitData } from "../../api";
import { getToken } from "../../utils/auth";
import wechat from "../../assets/tiktok-wechat.jpg";

import "./index.css";

class Overseas extends React.Component<any> {
  state = {
    overseasAuth: false,
    promoterId: "",
    profitData: {
      totalRevenue: 0,
      yesterdayRevenue: 0,
      yesterdayVisitorCount: 0,
      monthRevenue: 0,
      score: 0,
    },
  };
  componentDidMount() {
    this.checkAuth();
  }

  componentWillUnmount() {
    this.setState = () => false;
  }

  // 检测权限
  checkAuth = async () => {
    let token = getToken();
    let { data } = await getPromoterInfo({ token });
    if (data.code === "ok") {
      const { overseasAuth, promoterId } = data.data;
      this.setState({ overseasAuth, promoterId }, () => {
        this.getOverseasProfitData();
      });
    }
  };

  copyPromotionUrl = async (_: string, result: boolean) => {
    if (result) {
      Toast.success("复制成功");
    } else {
      Toast.fail("复制失败");
    }
  };

  getOverseasProfitData = async () => {
    const { overseasAuth } = this.state;
    if (!overseasAuth) return;
    const { data } = await getOverseasProfitData({
      token: getToken(),
    });
    if (data.code === "ok") {
      this.setState({ profitData: data.data });
    }
  };

  getScoreText = (score: number) => {
    if (score === 2) return "高";
    else if (score === 1) return "中";
    else return "低";
  };

  render() {
    const { overseasAuth, promoterId, profitData } = this.state;

    return (
      <div className="overseas-container">
        <Header text="Tiktok推广" arrow={false}></Header>
        {overseasAuth ? (
          <>
            <Card>
              <div className="top-card">
                <div className="card-header">
                  <div className="card-header__title">总收益</div>
                  <div className="card-header__tip">当月收益将在次月10号发放至个人中心</div>
                </div>
                <div className="card-body">
                  <div className="card-body__revenue">
                    <span>￥</span>
                    {profitData.totalRevenue}
                  </div>
                  <div className="card-body__sub-title">专属链接:</div>
                  <div className="card-body__url">
                    https://666-game.com/?id={promoterId}
                    <CopyToClipboard text={`https://666-game.com/?id=${promoterId}`} onCopy={this.copyPromotionUrl}>
                      <button>复制</button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </Card>
            <div className="sub-card-group">
              <Card>
                <div className="card-header">昨日收益</div>
                <div className="card-body">
                  <div className="card-body__content">
                    <span>￥</span>
                    {profitData.yesterdayRevenue}
                  </div>
                </div>
              </Card>
              <Card>
                <div className="card-header">本月预估</div>
                <div className="card-body">
                  <div className="card-body__content">
                    <span>￥</span>
                    {profitData.monthRevenue}
                  </div>
                </div>
              </Card>
              <Card>
                <div className="card-header">昨日转化</div>
                <div className="card-body">
                  <div className="card-body__content">{profitData.yesterdayVisitorCount}</div>
                </div>
              </Card>
              <Card>
                <div className="card-header">收益评级</div>
                <div className="card-body">
                  <div className="card-body__content">{this.getScoreText(profitData.score)}</div>
                </div>
              </Card>
            </div>
          </>
        ) : (
          <div className="tip-container">
            <div className="tip-text">
              <p>1. 注册Tiktok账号（加微信拿教程）</p>
              <p>2. 粉丝数量达到1000</p>
              <p>3. 联系抖花花负责人，获取专属推广链接</p>
              <p>4. 开始推广收钱</p>
            </div>
            <div className="tip-qrcode">
              <img src={wechat} alt="微信二维码" />
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default Overseas;
