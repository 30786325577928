import React from 'react';
import { NavBar, Icon } from 'antd-mobile';

export default class Header extends React.Component<any>{
  render(){
    
    return(
      <div>
        <NavBar
          style={{backgroundImage:'linear-gradient(to right, #0f1c44 , #00738a)', fontSize:17}}
          icon={this.props.arrow ? <Icon type="left" /> : null}
          onLeftClick={this.props.goBack }
          leftContent={this.props.leftContent}
        >{this.props.text}</NavBar>
      </div>
    )
  }
}