import React from 'react';
import Routers from './router/index';

function App() {
  return (
    // <Router>
    //   <div className="App">
    //     <Route path='/home' component={ Home } ></Route>
    //     {/* <Route path='/home/hamePage' component={ HomePage } ></Route> */}
    //     {/* <Route path='/my' component={ My } ></Route> */}
    //     <Route path="/" exact render={() => <Redirect to="/home" />} />
    //   </div>
    // </Router>
    <Routers></Routers>
  );
}

export default App;
