import React from "react";
import Header from "../../../../component/header";
import { Card, PullToRefresh, WingBlank } from "antd-mobile";
import "./index.css";
import { getGameOnlineSoonList } from "../../../../api";
import { getToken } from "../../../../utils/auth";

class newGameDetail extends React.Component<any> {
  state = {
    gameOnlineDetail: {
      described: "",
      gameScreenUrl: [],
    },
    height: document.documentElement.clientHeight,
    down: true,
    refreshing: false,
    appid: "",
    modalVisible: false,
    animating: false,
    erweimaUrl: "",
    avatarImgUrl: require("../../../../assets/avatar.png"),
    link: "",
    tabIndex: 0,
    gameOnlineList: [],
  };
  is_weixin = (function () {
    return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
  })();

  componentDidMount() {
    let { appid, gameOnlineDetail } = this.props.location.state;
    gameOnlineDetail && this.setState({ gameOnlineDetail, appid });
    this.getGameOnlineSoonList();
  }

  getGameOnlineSoonList = async () => {
    let token = getToken();
    let { data } = await getGameOnlineSoonList({ token });
    if (data.code === "ok") {
      this.setState({ gameOnlineList: data.data });
    }
  };
  // 点击,下拉刷新
  handleRefresh = () => {
    this.setState({ refreshing: true });
    this.getGameOnlineSoonList().then((res) => {
      setTimeout(() => {
        this.setState({ refreshing: false });
      }, 1000);
    });
  };

  goBack = () => {
    this.props.history.push("/home");
  };

  render() {
    const { gameOnlineDetail, appid, gameOnlineList } = this.state;
    // const { gameOnlineList } = this.props.location.state;

    const gameShowList = (
      <div style={{ marginBottom: 10 }}>
        <WingBlank>
          <div
            style={{
              margin: "15px auto",
              overflowX: "scroll",
              overflowY: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {gameOnlineList.map((item: any) => (
              <span
                key={item.appid}
                style={{
                  display: "inline-block",
                  whiteSpace: "nowrap",
                  width: "23%",
                  margin: "1%",
                  color: "white",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    width: "100%",
                    textAlign: "center",
                    borderBottom: item.appid === appid ? "3px solid gray" : "",
                  }}
                >
                  <img
                    src={item.IconOssUrl}
                    alt={item.appid}
                    style={{
                      width: "100%",
                      maxHeight: 144,
                      maxWidth: 144,
                      display: "inline-block",
                      borderRadius: 20,
                    }}
                    onClick={() => {
                      gameOnlineList.forEach((item1: any) => {
                        if (item1.appid.indexOf(item.appid) > -1) {
                          this.setState({
                            gameOnlineDetail: item,
                            appid: item.appid,
                          });
                        }
                      });
                    }}
                  />
                  <div style={{ marginTop: 10 }}>
                    <span>{item.appid}</span>
                  </div>
                </div>
              </span>
            ))}
          </div>
        </WingBlank>
      </div>
    );
    return (
      <div className="gameDetail">
        <PullToRefresh
          damping={60}
          style={{
            height: this.state.height,
            overflow: "auto",
          }}
          indicator={this.state.down ? {} : { deactivate: "上拉可以刷新" }}
          direction={this.state.down ? "down" : "up"}
          refreshing={this.state.refreshing}
          onRefresh={this.handleRefresh}
          distanceToRefresh={50}
          getScrollContainer={() => undefined}
        >
          <Header
            text="抖花花"
            arrow={true}
            goBack={this.goBack}
            leftContent="返回"
          ></Header>
          <Card style={{ color: "#141626" }}>
            <Card.Body>
              <div className="">{gameShowList}</div>
            </Card.Body>
          </Card>
          <div className="title">
            <h2>游戏介绍:</h2>
            <div>{gameOnlineDetail && gameOnlineDetail.described}</div>
            <div>
              <div
                style={{
                  display: "flex",
                  marginTop: 20,
                }}
              >
                {gameOnlineDetail &&
                  gameOnlineDetail.gameScreenUrl.map((item: any) => {
                    const suffixType = item.substring(item.lastIndexOf("."));
                    const suffix = [".mp4", ".gif"];
                    let type = "image";
                    suffix.forEach((item1: any) => {
                      if (item1.indexOf(suffixType) > -1) {
                        type = "video";
                      }
                    });
                    return (
                      <div key={item} style={{ marginRight: 10, width: "33%" }}>
                        {type === "image" ? (
                          <img
                            src={item}
                            style={{
                              width: "100%",
                              height: "auto",
                              maxHeight: "",
                              border: "1px solid gray",
                            }}
                            alt={item}
                          />
                        ) : (
                          <video
                            style={{ width: "20%" }}
                            src={item}
                            controls
                            autoPlay={false}
                          />
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </PullToRefresh>
      </div>
    );
  }
}

export default newGameDetail;
