import React from 'react';
import { TabBar } from 'antd-mobile';
import './index.css';

import My from '../My';
import HomePage from '../Index';
import Profit from '../Profit';
import { Route } from 'react-router-dom';
import LuckDraw from '../LuckDraw';
import Overseas from '../Overseas';


class Home extends React.Component<any> {
  state = {
    selectedTab: this.props.location.pathname,
  };

  render() {
    return (
      <div className="home">
        <Route path="/home/my" component={My} />
        <Route path="/home/profit" component={Profit} />
        <Route exact path="/home" component={HomePage} />
        <Route path="/home/LuckDraw" component={LuckDraw} />
        <Route path="/home/overseas" component={Overseas} />

        <TabBar
          unselectedTintColor="#5f628b"
          tintColor="#e42448"
          barTintColor="#1e1f28"
          noRenderContent={true}
          prerenderingSiblingsNumber={0}
        >
          <TabBar.Item
            title="首页"
            icon={<i className="iconfont">&#xe61f;</i>}
            selectedIcon={<i className="iconfont">&#xe61f;</i>}
            selected={this.state.selectedTab === "/home"}
            onPress={() => {
              this.setState({
                selectedTab: "/home",
              });
              // 路由切换
              this.props.history.push("/home");
            }}
          />
          <TabBar.Item
            title="收益"
            icon={<i className="iconfont">&#xe62d;</i>}
            selectedIcon={<i className="iconfont">&#xe62d;</i>}
            selected={this.state.selectedTab === "/home/profit"}
            onPress={() => {
              this.setState({
                selectedTab: "/home/profit",
              });
              // 路由切换
              this.props.history.push("/home/profit");
            }}
          />
          {/* <TabBar.Item
            title="出海"
            icon={<i className="iconfont">&#xe684;</i>}
            selectedIcon={<i className="iconfont">&#xe684;</i>}
            selected={this.state.selectedTab === "/home/overseas"}
            onPress={() => {
              this.setState({
                selectedTab: "/home/overseas",
              });
              // 路由切换
              this.props.history.push("/home/overseas");
            }}
          /> */}
{/* 
          <TabBar.Item
            title="抽奖"
            icon={<i className="iconfont">&#xe649;</i>}
            selectedIcon={<i className="iconfont">&#xe649;</i>}
            selected={this.state.selectedTab === `/home/LuckDraw/`}
            onPress={() => {
              this.setState({
                selectedTab: `/home/LuckDraw/`,
              });
              // 路由切换
              this.props.history.push(`/home/LuckDraw`);
            }}
          /> */}
          <TabBar.Item
            title="个人中心"
            icon={<i className="iconfont">&#xe651;</i>}
            selectedIcon={<i className="iconfont">&#xe651;</i>}
            selected={this.state.selectedTab === "/home/my"}
            onPress={() => {
              this.setState({
                selectedTab: "/home/my",
              });
              // 路由切换
              this.props.history.push("/home/my");
            }}
          />
        </TabBar>
      </div>
    );
  }
}

export default Home