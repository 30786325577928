import React from "react";
import { Tabs } from "antd-mobile";
export default class TabPlat extends React.Component<any> {
  state = {
    index: 0,
  };

  render() {
    const tabs = [
      { title: <span>CPA专区</span> },
      { title: <span>CPS专区</span> },
    ];
    const { index } = this.state;
    const tabStyle = {
      background: "#1e203c",
    };
    const TabExample = () => (
      <div
        style={{
          marginBottom: "1.5rem",
          fontSize: "1rem",
          // border: "1px solid #f3cd3d",
          borderRadius:3
        }}
        className="tabPlatForm"
      >
        <Tabs
          tabs={tabs}
          initialPage={index}
          onChange={(tab: any, index: any) => {
            this.setState({ index });
            this.props.tabChange && this.props.tabChange(tab, index);
          }}
          tabBarTextStyle={tabStyle}
        ></Tabs>
      </div>
    );
    return (
      <div>
        <TabExample />
      </div>
    );
  }
}
