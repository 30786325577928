import React from "react";
import {
  List,
  InputItem,
  Toast,
  Button,
  ActivityIndicator,
  WingBlank,
} from "antd-mobile";
import { createForm } from "rc-form";
import "./index.css";
import { getverificationCode, login } from "../../api";
import { setToken } from "../../utils/auth";

interface pageState {
  animating: boolean;
  count: number;
  liked: boolean;
  btnDisable: boolean;
  imgUrl: any;
  inviteCode: any;
}
class Login extends React.Component<any, pageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      animating: false,
      count: 60,
      liked: true,
      btnDisable: false,
      imgUrl: require("../../assets/avatar.png"),
      inviteCode: "",
    };
  }
  formVal = {
    phone: "",
    code: "",
    inviteCode: "",
  };
  componentDidMount() {
    let sid = this.props.location.search;
    let inviteCode = sid.substring(sid.lastIndexOf("=") + 1, sid.length);
    // this.formVal.inviteCode = inviteCode
    this.setState({ inviteCode: inviteCode });
  }
  showToast = () => {
    this.setState({ animating: !this.state.animating });
  };

  onSubmit = () => {
    this.props.form.validateFields(async (error: any, value: any) => {
      if (!error) {
        this.formVal.phone = value.phone;
        this.formVal.code = value.code;
        this.formVal.inviteCode = value.inviteCode;
        let { data } = await login(this.formVal);
        if (!data) {
          Toast.info("验证码错误");
        }
        if (data.code === "ok") {
          setToken(data.data.token);
          this.props.history.push("/home");
          this.props.history.go();
        }
        if (data.code === "error") {
          Toast.info(data.error);
        }
      } else {
        Toast.info("请填写正确所有的字段");
      }
    });
  };

  validateTel = (rule: any, value: any, callback: any) => {
    if (value && value.length === 11) {
      callback();
    } else if (value.length === 0) {
      callback(Toast.fail("请输入电话号码"));
    } else {
      callback(Toast.fail("电话号码不合法"));
    }
  };

  validateCode = (rule: any, value: any, callback: any) => {
    if (value && value.length === 6) {
      callback();
    } else if (value.length === 0) {
      callback(Toast.fail("请输入验证码"));
    } else {
      callback(Toast.fail("验证码不合法"));
    }
  };

  // 发送验证码
  verificationCode = async () => {
    this.setState({ animating: true });
    this.formVal.phone = this.props.form.getFieldValue("phone");
    if (!this.formVal.phone) {
      Toast.info("请输入电话号码", 1);
      return;
    }
    const { liked } = this.state;
    if (!liked) {
      return;
    }

    let { data } = await getverificationCode({ phone: this.formVal.phone });
    if (data.code === "ok") {
      this.setState({ animating: false });
      this.countDown();
    }
  };

  // 验证码倒计时
  countDown() {
    const { count } = this.state;
    if (count === 1) {
      this.setState({
        count: 60,
        liked: true,
        btnDisable: false,
      });
    } else {
      this.setState({
        count: count - 1,
        liked: false,
        btnDisable: true,
      });
      setTimeout(this.countDown.bind(this), 1000);
    }
  }

  goPrivacy = (val: string) => {
    this.props.history.push({ pathname: "/my/privacy", state: val });
    this.props.history.go();
  };
  render() {
    const { getFieldProps } = this.props.form;
    return (
      <div className="login">
        <div className="loginLogo">
          <img src={this.state.imgUrl} alt="" />
        </div>
        <List>
          <InputItem
            {...getFieldProps("phone", {
              validateTrigger: "onBlur",
              rules: [{ validator: this.validateTel }, { required: true }],
            })}
            type="tel"
            maxLength={11}
            placeholder="请输入手机号码"
          ></InputItem>
          <div className="codeBox">
            <InputItem
              {...getFieldProps("code", {
                // rules: [{ validator: this.validateCode }],
              })}
              type="code"
              maxLength={6}
              placeholder="请输入验证码"
            ></InputItem>
            <button
              className="sendCodeBtn"
              onClick={this.verificationCode}
              disabled={this.state.btnDisable}
            >
              {this.state.liked ? "获取验证码" : `${this.state.count} 秒后重发`}
            </button>
          </div>
          <InputItem
            {...getFieldProps("inviteCode", {
              initialValue: this.state.inviteCode,
              validateTrigger: "onBlur",
            })}
            maxLength={6}
            placeholder="邀请码"
            disabled={this.state.inviteCode}
          ></InputItem>
          <div className="submitBtn">
            <Button type="primary" onClick={this.onSubmit}>
              登录
            </Button>
          </div>
        </List>
        <div className="footerInfo">
          登录既表明您已阅读并同意我们的
          <span onClick={() => this.goPrivacy("隐私政策")}>《隐私政策》</span>与
          <span onClick={() => this.goPrivacy("服务协议")}>《服务协议》</span>
        </div>
        <div
          className="recordNo"
          onClick={() => (window.location.href = "http://beian.miit.gov.cn/")}
        >
          {" "}
          闽ICP备15001614号-4
        </div>
        <WingBlank>
          <div className="loading-container">
            <div className="loading-example">
              <div className="align">
                <ActivityIndicator
                  size="large"
                  toast
                  text="正在加载"
                  animating={this.state.animating}
                />
                <span style={{ marginTop: 8 }}></span>
              </div>
            </div>
          </div>
        </WingBlank>
      </div>
    );
  }
}

// const Login = Form.create<any>()();
export default createForm()(Login);
