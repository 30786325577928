import React from "react";
import Header from "../../component/header";
import TabPlat from "../../component/tabPlat";
import { createForm } from "rc-form";
import "./index.css";
import {
  Icon,
  WingBlank,
  Card,
  Modal,
  ActivityIndicator,
  PullToRefresh,
  Toast,
  InputItem,
  Carousel,
} from "antd-mobile";
import {
  gameList,
  QRCode,
  getPromoterInfo,
  getGameOnlineSoonList,
  getGameNoticeList,
  addPromoterLuckDrawCount,
} from "../../api";
import { getToken, parentId, setCooperatorId } from "../../utils/auth";

interface pageState {
  gameOnlineList: any;
  GameNotice: any;
  NoticeData: any;
  gameList: any;
  erweimaUrl: any;
  modalVisible: boolean;
  animating: boolean;
  refreshAnimating: boolean;
  height: any;
  down: boolean;
  refreshing: boolean;
  showScroll: boolean;
  avatarImgUrl: any;
  bannerImgUrl: any;
  goTopImgUrl: any;
  emptyImg: any;
  link: any;
  tabType: any;
  promoterId: any;
}
class Homepage extends React.Component<any, pageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      gameOnlineList: [],
      GameNotice: [],
      NoticeData: {},
      gameList: [],
      modalVisible: false,
      erweimaUrl: "",
      animating: false,
      refreshAnimating: false,
      height: document.documentElement.clientHeight,
      down: true,
      refreshing: false,
      showScroll: false,
      link: "",
      avatarImgUrl: require("../../assets/avatar.png"),
      bannerImgUrl: require("../../assets/banner.png"),
      goTopImgUrl: require("../../assets/totop.png"),
      emptyImg: require("../../assets/empty.png"),
      tabType: "CPA",
      promoterId: "",
    };
  }
  pageValue = {
    game: "",
  };
  componentDidMount() {
    this.promoterInfo();
    this.getGameList();
    this.bindEvent();
    this.getGameOnlineSoonList();
    this.getGameNoticeList();
  }

  //更新用户抽奖次数
  addPromoterLuckDrawCount = async () => {
    const { promoterId } = this.state;
    const token = localStorage.getItem("token");
    await addPromoterLuckDrawCount({
      id: promoterId,
      token,
    });
  };

  getGameOnlineSoonList = async () => {
    let token = getToken();
    let { data } = await getGameOnlineSoonList({ token });
    if (data.code === "ok") {
      this.setState({ gameOnlineList: data.data });
    }
  };
  getGameNoticeList = async () => {
    let token = getToken();
    let { data } = await getGameNoticeList({ token });
    if (data.code === "ok") {
      this.setState({ GameNotice: data.data });
    }
  };
  componentWillUnmount() {
    window.removeEventListener("scoll", this.toggleTopShow);
    this.setState = (state, callback) => {
      return;
    };
  }

  // 用户信息
  promoterInfo = async () => {
    let token = getToken();
    let { data } = await getPromoterInfo({ token });
    if (data.code === "ok") {
      this.setState({
        promoterId: data.data.promoterId,
      });
      parentId(data.data.promoterId);
      setCooperatorId(data.data.cooperatorId);
      this.addPromoterLuckDrawCount();
    }
  };
  bindEvent = () => {
    window.addEventListener("scroll", this.toggleTopShow);
  };
  // 显示回到顶部按钮
  toggleTopShow = () => {
    let showScroll;
    if (document.documentElement.scrollTop > 800) {
      showScroll = true;
    } else {
      showScroll = false;
    }
    this.setState({
      showScroll,
    });
  };
  //  点击回到顶部
  handleScrollTop = () => {
    window.scrollTo(0, 0);
  };
  // 获取游戏列表
  getGameList = async () => {
    const { tabType } = this.state;
    let token = getToken();
    let { data } = await gameList({ token, tabType });
    if (data.code === "ok") {
      this.setState({ gameList: data.data });
    }
  };
  // 点击,下拉刷新
  handleRefresh = () => {
    this.setState({ refreshing: true });
    this.getGameList().then((res) => {
      setTimeout(() => {
        this.setState({ refreshing: false });
      }, 500);
    });
    this.promoterInfo();
  };

  // 获取二维码
  handleErweima = async (e: any, appid: any) => {
    e.stopPropagation();
    this.setState({ modalVisible: true, animating: true });
    let token = getToken();
    let { data } = await QRCode({ token, appid });
    if (data.code === "ok") {
      this.setState({
        erweimaUrl: data.data.base64DataURI,
        animating: false,
        link: data.data.url,
      });
    }
  };
  // 点击复制链接
  copyLink = () => {
    const input = document.createElement("input");
    document.body.appendChild(input);
    input.setAttribute("value", `${this.state.link}`);
    input.select();
    if (document.execCommand("copy")) {
      document.execCommand("copy");
    }
    document.body.removeChild(input);
    Toast.info("链接复制成功!", 2);
  };

  // 点击游戏卡片去详情
  goGameDetail = (appid: any) => {
    this.props.history.push(`/gameDetail?appid=${appid}`);
  };
  // 关闭二维码弹框
  onClose = () => {
    this.setState({ modalVisible: false });
  };
  // 去公告页面
  goPropaganda = () => {
    this.props.history.push("/propaganda");
  };
  // 去banner操作图页面
  goBannerPage = (data: any) => {
    this.props.history.push("/bannerPage", {
      data: data,
    });
  };
  // 搜索游戏
  handleSearch = () => {
    this.props.form.validateFields(async (error: any, value: any) => {
      if (!error) {
        this.pageValue.game = value.gameName;
        let gameName = this.pageValue.game;
        const { tabType } = this.state;
        let token = getToken();
        let { data } = await gameList({ token, gameName, tabType });
        if (data.code === "ok") {
          this.setState({ gameList: data.data });
        }
        if (!data) {
          Toast.info("请输入正确的游戏名称", 1);
        }
      }
    });
  };

  //点击查看游戏详情
  goNewGameDetail = (
    appid: any,
    gameOnlineList: any,
    gameOnlineDetail: any
  ) => {
    this.props.history.push({
      pathname: "/newGameDetail",
      state: { appid, gameOnlineList, gameOnlineDetail },
    });
  };
  //不同的平台类型的请求
  tabChange = (tab: any, index: any) => {
    //index 0表示CPA 1表示CPS
    const tabTypeArr = ["CPA", "CPS"];
    const tabType = tabTypeArr[index];
    this.setState({ tabType }, () => {
      this.getGameList();
    });
  };
  render() {
    const {
      gameOnlineList,
      gameList,
      modalVisible,
      animating,
      avatarImgUrl,
      bannerImgUrl,
      goTopImgUrl,
      emptyImg,
      link,
      GameNotice,
    } = this.state;
    const { getFieldProps } = this.props.form;
    const gameListDom = gameList.map((item: any) => (
      <Card onClick={(e) => this.goGameDetail(item.appid)} key={item.name}>
        <Card.Body>
          <div className="gameList" style={{ display: "flex" }}>
            <div>
              <img src={item.avatar ? item.avatar : avatarImgUrl} alt="" />
            </div>

            <div>
              <span>
                {item.hideGame === 0
                  ? item.name
                  : "****" + item.name.substring(4)}
              </span>
              <span>游戏</span>
              <div className="incomeGrade">
                收益等级
                {item.gameWeight === "0" ? (
                  <i className="iconfont">
                    &#xe6b9;&#xe6b9;&#xe6b9;&#xe6b9;&#xe6b9;
                  </i>
                ) : (
                  ""
                )}
                {item.gameWeight === "1" ? (
                  <i className="iconfont">
                    &#xe6b9;&#xe6b9;&#xe6b9;&#xe6b9;
                    <i className="iconfont" style={{ color: "#5b5b5b" }}>
                      &#xe6b9;
                    </i>
                  </i>
                ) : (
                  ""
                )}
                {item.gameWeight === "2" ? (
                  <i className="iconfont">
                    &#xe6b9;&#xe6b9;&#xe6b9;
                    <i className="iconfont" style={{ color: "#5b5b5b" }}>
                      &#xe6b9;&#xe6b9;
                    </i>
                  </i>
                ) : (
                  ""
                )}
                {item.gameWeight === "3" || item.gameWeight === "4" ? (
                  <i className="iconfont">
                    &#xe6b9;&#xe6b9;
                    <i className="iconfont" style={{ color: "#5b5b5b" }}>
                      &#xe6b9;&#xe6b9;&#xe6b9;
                    </i>
                  </i>
                ) : (
                  ""
                )}
                <div style={{ padding: "8px 0 0" }}>合作方式：{item.cooperationMode}</div>
                <div style={{ padding: "4px 0 0" }}>
                  {item.cooperationMode === "CPA" ? `单价：${item.unitPrice}元` : `分成比例：${item.percentage * 100}%`}
                </div>
              </div>
            </div>
            <div className="HighestIncome">
              <p>单视频最高收入</p>
              <p>
                {Number(item.HighestIncome) >= 1000
                  ? `${Number(item.HighestIncome).toFixed(2)}元`
                  : "上涨中"}
              </p>
            </div>
          </div>
        </Card.Body>
        <div className="taskIntroduction">{item.taskIntroduction}</div>
        <Card.Footer />
      </Card>
    ));
    return (
      <div className="homePage">
        <PullToRefresh
          damping={60}
          style={{
            height: this.state.height,
            overflow: "auto",
          }}
          indicator={this.state.down ? {} : { deactivate: "上拉可以刷新" }}
          direction={this.state.down ? "down" : "up"}
          refreshing={this.state.refreshing}
          onRefresh={this.handleRefresh}
          distanceToRefresh={50}
          getScrollContainer={() => undefined}
        >
          <Header text="抖花花" arrow={false}></Header>
          <WingBlank>
            <div className="banner">
              {GameNotice.length > 1 ? (
                <Carousel frameOverflow="visible" autoplay={true} infinite>
                  {GameNotice &&
                    GameNotice.map((item: any) => {
                      const url = encodeURI(item.NoticeBarUrl);
                      return (
                        <img
                          key={url}
                          className="banner"
                          src={item.NoticeBarUrl !== "" ? url : bannerImgUrl}
                          alt=""
                          onClick={() => this.goBannerPage(item)}
                        />
                      );
                    })}
                </Carousel>
              ) : (
                <img
                  src={
                    GameNotice.length > 0
                      ? GameNotice[0].NoticeBarUrl
                      : bannerImgUrl
                  }
                  onClick={() => this.goBannerPage(GameNotice[0])}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              )}
            </div>
            <div className="info" onClick={this.goPropaganda}>
              <span>平台</span>
              <span>公告</span>
              <span>抖花花推广宣传要求</span>
              <Icon type="right"></Icon>
              <div className="Notice">
                <p className="Notice_animate">
                  作品严禁搬运及画面完全不相关，请达人配合遵守，如有疑问，可咨询抖花花官方人员
                </p>
              </div>
            </div>
          </WingBlank>
          <div style={{ marginBottom: 30 }}>
            <WingBlank>
              <div
                style={{
                  display: "flex",
                  fontSize: 20,
                  justifyContent: "space-between",
                }}
              >
                <span>新游推荐</span>
                {gameOnlineList.length !== 0 ? (
                  <span>
                    <span
                      onClick={(e) =>
                        this.goNewGameDetail(
                          gameOnlineList[0].appid,
                          gameOnlineList,
                          gameOnlineList[0]
                        )
                      }
                    >
                      {"查看更多"}
                      <span style={{ fontSize: 23 }}>{">"}</span>
                    </span>
                  </span>
                ) : null}
              </div>
              <div
                style={{
                  margin: "1px auto",
                  overflow: "scroll hidden",
                  whiteSpace: "nowrap",
                  marginTop: 15,
                }}
              >
                {gameOnlineList.length !== 0 ? (
                  gameOnlineList.map((item: any) => (
                    <span
                      key={item.appid}
                      style={{
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        touchAction: "auto",
                        width: "23%",
                        margin: "1%",
                        color: "white",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={item.IconOssUrl}
                          alt={item.appid}
                          style={{
                            width: "100%",
                            maxHeight: 144,
                            maxWidth: 144,
                            display: "inline-block",
                            borderRadius: 10,
                          }}
                          onClick={(e) =>
                            this.goNewGameDetail(
                              item.appid,
                              gameOnlineList,
                              item
                            )
                          }
                        />
                        <div
                          style={{
                            marginTop: 10,
                            textAlign: "center",
                            fontSize: "0.7rem",
                          }}
                        >
                          <span>{item.appid}</span>
                        </div>
                      </div>
                    </span>
                  ))
                ) : (
                  <div
                    style={{
                      height: 40,
                      textAlign: "center",
                      lineHeight: "40px",
                      color: "gray",
                    }}
                  >
                    暂无相关数据...
                  </div>
                )}
              </div>
            </WingBlank>
          </div>

          <div className="list">
            <WingBlank>
              <div className="title">
                <span>产品列表</span>
                <span>
                  共{" "}
                  <span style={{ color: "#e0b43f", fontSize: 12 }}>
                    {gameList.length > 0 ? gameList.length : 0}
                  </span>{" "}
                  款小程序
                </span>
              </div>
              <button onClick={this.handleRefresh}>游戏</button>
              <div className="searchBox">
                <InputItem
                  {...getFieldProps("gameName")}
                  placeholder="请输入游戏名称"
                  className="searchGame"
                ></InputItem>
                <span className="searchBtn" onClick={this.handleSearch}>
                  搜索
                </span>
              </div>
              <div className="TabPlatContainer">
                <TabPlat tabChange={this.tabChange} />
              </div>
              <div>
                {gameList.length !== 0 ? (
                  gameListDom
                ) : (
                  <div className="emptyImg">
                    <img src={emptyImg} alt={emptyImg} />
                    <p>暂无数据</p>
                  </div>
                )}
              </div>
              {/* {gameListDom} */}
              <div
                className="foote"
                style={{ display: gameList.length > 1 ? "block" : "none" }}
              >
                {" "}
                -- 到底啦 --{" "}
              </div>
              <div className="goBackBtnBox">
                {this.state.showScroll ? (
                  <div onClick={this.handleScrollTop} className="goBackBtn">
                    <img src={goTopImgUrl} alt="" />
                  </div>
                ) : null}
              </div>
            </WingBlank>
          </div>
          <Modal
            visible={modalVisible}
            transparent
            maskClosable
            onClose={this.onClose}
            footer={[
              {
                text: "Ok",
                onPress: () => {
                  console.log("ok");
                  this.onClose();
                },
              },
            ]}
            style={{ height: "70%", width: "85%" }}
          >
            {animating ? (
              <ActivityIndicator
                size="large"
                toast
                text="正在加载"
                animating={animating}
              />
            ) : (
              <div
                className="erweimaModal"
                style={{ display: animating ? "none" : "block" }}
              >
                <img
                  src={this.state.erweimaUrl}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
                <div style={{ fontSize: 18, color: "#ff004a" }}>
                  长按保存图片
                </div>
                <div className="link" onClick={this.copyLink}>
                  {link}
                </div>
              </div>
            )}
          </Modal>
        </PullToRefresh>
      </div>
    );
  }
}

export default createForm()(Homepage);
