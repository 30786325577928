import React from 'react';
import Header from '../../../../component/header'
import moment from 'moment';
import { Card, Modal, ActivityIndicator, Toast, Tabs, WingBlank, Flex } from 'antd-mobile';
import './index.css'
import { gameDetail, QRCode, gameList, getGameVideoList } from '../../../../api'
import {  getToken, getParentId } from '../../../../utils/auth'

class Gamedetail extends React.Component<any>{
  appid = "";
  state = {
    gameDetail:{
      appid: "",
      avatar: "",
      description: "",
      name: "",
      date:'',
      taskIntroduction:'',
      materialDownload:'',
      officiGuidance:'',
      rule:'',
      talentNum:'',
      contributions:'',
      video:[],
      gameVideoList:[],
      HighestIncome:'',
      hideGame:0,
    },
    modalVisible:false,
    animating:false,
    erweimaUrl:'',
    avatarImgUrl: require('../../../../assets/avatar.png'),
    link:'',
    tabIndex: 0,
    gameList:[],
    gameVideoList:[]
  };
  is_weixin = (function(){ return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1 })();
  is_douyin = (function () {
    return (
      navigator.userAgent.toLowerCase().indexOf("toutiao") !== -1 ||
      navigator.userAgent.toLowerCase().indexOf("douyin") !== -1 ||
      navigator.userAgent.toLowerCase().indexOf("bytedance") !== -1
    );
  })();

  componentDidMount(){
    let appid = this.props.history.location.search
    appid = appid.substring(appid.lastIndexOf("=")+1, appid.length)  
    this.appid = appid
    this.getGameDetail(appid)
    this.getGameList()
  }

  getGameList = async () => {
    let token = getToken()
    let {data} = await gameList({token})
    if(data.code === 'ok'){
      this.setState({gameList:data.data})
    }
  }

  getGameDetail = async (appid:any) => {
    let token = getToken()
    let  parentId = getParentId()
    let { data } = await gameDetail({token,appid,parentId})
    if(data.code === 'ok') {
      this.setState ({gameDetail:data.data})
      // let videoList = this.state.gameDetail.gameVideoList
      // this.gameVideoList(videoList)
    } 
  }
  goBack = () => {
    this.props.history.push('/home')
   }

   // 获取二维码
  handleErweima = async (e:any, appid:any) => {
    e.stopPropagation()
    if (!appid) {
      return false;
    }
    this.setState({modalVisible:true,animating:true})
    let token = getToken()
    let {data} = await QRCode({token,appid})
    if(data.code === 'ok'){
      this.setState({erweimaUrl:data.data.base64DataURI,animating:false,link:data.data.url})
    }
  }
  // 点击复制链接
  copyLink = () => {
    const input = document.createElement('input');
    document.body.appendChild(input);
      input.setAttribute(
        'value',
        `${this.state.link}`,
      );
    input.select();
    if (document.execCommand('copy')) {
      document.execCommand('copy');
    }
    document.body.removeChild(input);
    Toast.info('链接复制成功!',2)
  }
   // 关闭二维码弹框
  onClose = () => {
    this.setState({modalVisible:false});
  }
  onChange = (tab:any, index:any) =>{
    this.setState({tabIndex:index})
  }
  onTabClick = (tab:any,index:any) => { }
  // 点击游戏卡片去详情
  goGameDetail = (appid:any) => {
    this.props.history.push(`/gameDetail?appid=${appid}`)
    window.location.reload()
  }
  // 点击拍视频跳转
  takeVideo = (appid:any) => {
    let promoterId = getParentId()
    let now = moment().locale('zh-cn').format('YYYY-MM-DD');
    window.location.href =
    `sslocal://microgame?version=v2&app_id=${appid}&scene=021002&version_type=current&bdpsum=d657c08&query=` + encodeURIComponent(`{"promoterId":"${promoterId}","promoDate":"${now}","promoMission":"1"}`);
  }
  // 点击拍视频跳转
  takeVideoKS = (appid:any) => {
    let promoterId = getParentId()
    window.location.href = `/api/ttMission/kuaishouPromo?appId=${appid}&promoterId=${promoterId}`;
  }
  // 游戏详情的视频榜单
  gameVideoList = async(videoList:any) => {
    let gameVideo:any = []
    
    videoList && videoList.forEach((item:any)=>{
      if(item.videoId){
        gameVideo.push(item.videoId)
      }
    })
    const { data:list } = await getGameVideoList(gameVideo)
    list.data && list.data.forEach((item1:any)=>{
      videoList.forEach((item2:any)=>{
        if(item1.alias_id === item2.videoId && item1.video_info!== "视频不存在或已删除"){
          item1.video_info.nickName = item2.nickName
          item1.video_info.promoProfit = item2.promoProfit
        }
      })
    })
    list.data && list.data.sort((a:any,b:any) =>{
      return b.video_info.digg_count - a.video_info.digg_count
  })
    this.setState({gameVideoList:list.data})
    
  }
  // 点击视频榜单跳转
  goGameDetailVideo = (alias_id:any) => {
    window.location.href = `sslocal://microapp?version=v2&app_id=ttf1ed87506ed645bb&scene=016002&version_type=current&start_page=pages%2Findex%2Findex%3FvideoId%3D${alias_id}`
  }
  render(){
    const { gameDetail, modalVisible, animating, avatarImgUrl, link, tabIndex, gameList, gameVideoList } = this.state
    
    const tabs = [
      { title: 
        <div className="tabsTitle">
          <div style={{width:'100px',height:'30px'}}>任务介绍</div>
          <button className={tabIndex === 0 ? 'originBtn tabsActiveBtn' : 'originBtn'}>活动上新</button>
        </div> },
      { title:
        <div className="tabsTitle">
          <div style={{width:'100px',height:'30px'}}>创作素材</div>
          <button className={tabIndex === 1 ? 'originBtn tabsActiveBtn' : 'originBtn'}>助力上热门</button>
        </div>},
      { title:
        <div className="tabsTitle" style={{border:'none'}}>
          <div style={{width:'100px',height:'30px'}}>平台规则</div>
          <button className={tabIndex === 2 ? 'originBtn tabsActiveBtn' : 'originBtn'}>新手指南</button>
        </div>},
    ];
    return (
      <div className="gameDetail">
        <Header text="抖花花" arrow={true} goBack={ this.goBack} leftContent='返回'></Header>
           <Card style={{color:"#141626"}}>
              <Card.Body>
                <div className="detailGameList">
                  <div>
                    <img src={gameDetail.avatar ? gameDetail.avatar : avatarImgUrl} alt=""/>
                  </div>
                  <div>
                    <span>
                     {gameDetail.hideGame === 0
                    ? gameDetail.name
                    : "****" + gameDetail.name.substring(4)}
                    </span>
                    <span>游戏</span>
                    <div className="incomeGrade">收益等级 <i className="iconfont">&#xe6b9;&#xe6b9;&#xe6b9;&#xe6b9;<i className="iconfont" style={{color:'#5b5b5b'}}>&#xe6b9;</i></i></div>
                    {/* <span style={{color:'#bcbdbe',fontSize:12,paddingTop:10}}>上架时间: {gameDetail.date}</span> */}
                  </div>
                  <div>
                    {
                      this.appid.startsWith("ks") ? (
                        <div onClick={(e) => this.handleErweima(e, this.appid)} className="downloadIcon">
                          <i className="iconfont" style={{ color: "#fe2b54" }}>
                            &#xe60a;
                          </i>
                          <p>下载推广码</p>
                        </div>
                      ) : this.is_douyin ? (
                        <div className="takeVideoBox">
                          <div className="takeVideo" onClick={() => this.takeVideo(this.appid)}>
                            拍视频
                          </div>
                        </div>
                      ) : (
                        <div onClick={(e) => this.handleErweima(e, this.appid)} className="downloadIcon">
                          <i className="iconfont" style={{ color: "#fe2b54" }}>
                            &#xe60a;
                          </i>
                          <p>下载推广码</p>
                        </div>
                      )
                    }
                  </div>
                </div>
              </Card.Body>
              <Card.Footer content={gameDetail.description}/>
          </Card>
          <div className="detailData">
            <Flex>
              <Flex.Item>
                <p>投稿达人数量</p>
                <p>{gameDetail.talentNum || 0}</p></Flex.Item>
              <Flex.Item>
                <p>投稿数量</p>
                <p>{gameDetail.contributions || 0}</p>
              </Flex.Item>
              <Flex.Item>
                <p>单视频最高收入</p>
                  <p>
                    {Number(gameDetail.HighestIncome) >= 1000
                      ? `${Number(gameDetail.HighestIncome).toFixed(2)}元`
                      : "上涨中"}
                  </p>
              </Flex.Item>
            </Flex>
          </div>
          <Tabs tabs={tabs}
            initialPage={0}
            tabBarBackgroundColor={'#15171e'}
            tabBarActiveTextColor={'#e42448'}
            animated={false}
            swipeable={false}
            onChange={(tab, index) => {this.onChange(tab,index)}}
            onTabClick={(tab, index) => {this.onTabClick(tab,index)}}
          >
            <div style={{ display: 'flex', backgroundColor: '#15171e' }}>
              <WingBlank size="lg">
                <h4>任务说明</h4>
                { gameDetail.taskIntroduction ? 
                  <pre>
                    {gameDetail.taskIntroduction}
                  </pre> : 
                  <pre>
                    <p className="waiting">准备中,稍后再来看吧~</p>
                  </pre>
                }
                <div>
                  <h4>视频榜单</h4>
                  { gameVideoList ? 
                    (gameVideoList && gameVideoList.map((item:any)=>(
                      item.video_info.cover_url ?
                      <Card style={{backgroundColor:'#1c1d34', marginTop:20}} key={item.alias_id} onClick={(e)=>this.goGameDetailVideo(item.alias_id)}>
                        <Card.Body className="gameVideoList">
                            <div style={{ marginTop:15,width:'25%'}}>
                              <img src={item.video_info.cover_url} alt="" style={{width:'100%',height:100}}/>
                            </div>
                            <div style={{width:'35%'}}>
                              <div>{item.video_info.nickName}</div>
                              <div>点赞: <span  style={{color:'#ff4c30'}}>{item.video_info.digg_count}</span></div>
                            </div>
                            <div>
                              <span style={{color:'#ff5434'}}>￥{item.video_info.promoProfit}</span>
                            </div>
                        </Card.Body>
                      </Card> : null
                    )))
                  : <p className="waiting">准备中,稍后再来看吧~</p>
                }
                </div>
              </WingBlank>
            </div>
            <div style={{ display: 'flex', backgroundColor: '#15171e', marginBottom:20 }}>
              <WingBlank size="lg">
                <h4>官方指导</h4>
                <pre>
                  {gameDetail.officiGuidance ? gameDetail.officiGuidance : 
                    <p className="waiting">准备中,稍后再来看吧~</p>
                  }
                </pre>
                <h4>视频指导</h4>
                {gameDetail.video && gameDetail.video.length > 0 ? 
                  <div className="videoGuide">
                  { gameDetail.video.map((item:any, index:any)=>(
                      <div onClick={()=> window.location.href = item} key={item} style={{margin:5,marginLeft:0,color:'#d8b705'}}> 
                        <span style={{borderBottom:'1px solid #d8b705'}}>
                         视频指导素材 {index+1}
                        </span>
                      </div>
                  ))}
                  </div>
                : 
                  <pre>
                    <p className="waiting">准备中,稍后再来看吧~</p>
                  </pre>
                  }
                
                <h4>素材下载</h4>
                <pre>
                  {gameDetail.materialDownload ? gameDetail.materialDownload : 
                    <p className="waiting">准备中,稍后再来看吧~</p>
                  }
                </pre>
              </WingBlank>
            </div>
            <div style={{ display: 'flex', backgroundColor: '#15171e'}}>
              <WingBlank size="lg">
                <h4>平台规则</h4>
                <pre>
                  {gameDetail.rule ? gameDetail.rule :
                    <p className="waiting">准备中,稍后再来看吧~</p>
                  }
                </pre>
                <h4 className="task">推荐任务</h4>
                {gameList.map((item:any) => (
                <Card style={{ backgroundColor: '#1c1d34' }} key={item.name} onClick={(e)=>this.goGameDetail(item.appid)}>
                  <Card.Body >
                    <div className="gameList" style={{display:'flex'}}>
                      <div>
                      <img
                        src={item.avatar ? item.avatar : avatarImgUrl}
                        alt=""
                      />
                      </div>
                      
                      <div>
                        <span>{item.name}</span>
                        <span>游戏</span>
                        <div className="incomeGrade">收益等级 <i className="iconfont">&#xe6b9;&#xe6b9;&#xe6b9;&#xe6b9;<i className="iconfont" style={{color:'#5b5b5b'}}>&#xe6b9;</i></i></div>
                      </div>
                      <div>
                        <div onClick={(e) => this.handleErweima(e,item.appid)} className="downloadIcon">
                          <i className="iconfont" style={{ color: '#fe2b54' }} >&#xe60a;</i>
                          <p>下载推广码</p>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Footer content={item.description}/>
                </Card>
                ))}
              </WingBlank>
            </div>
          </Tabs>
          <Modal
            visible={modalVisible}
            transparent
            maskClosable
            onClose={this.onClose}
            footer={[{ text: 'Ok', onPress: () => { console.log('ok'); this.onClose(); } }]}
            style={{ height: '70%', width:'85%'}}
          >
            {animating ?
            <ActivityIndicator size="large" toast text="正在加载" animating={animating}/> :
            <div  className="erweimaModal" style={{display:animating ? 'none' : 'block'}}>
              <img src={this.state.erweimaUrl} alt="" style={{width:'100%',height:'100%'}}/>
              <div style={{fontSize:18, color:'#ff004a'}}>长按保存图片</div>
              <div  onClick={this.copyLink} className="link">{link}</div>
              <div><p>{`游戏名：${this.state.gameDetail.name}`}</p></div>
            </div>
            }
        </Modal>
      </div>
    )
  }
}

export default Gamedetail